/* @import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100..900&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Libre+Franklin:100,100i,200,200i,300,300i,400,500,500i,600,600i,700,700i,800,800i,900,900i|Playfair+Display:400,400i,700,700i,900,900i|Abril+Fatface&amp;subset=latin-ext");

@import "assets/libs/FontAwesome_v6.5.2/css/all.css";
@import "toastr";

/*
font-family: 'M PLUS Rounded 1c', sans-serif;
font-family: 'Open Sans', sans-serif;
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background-color: transparent;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(203, 213, 225, 0.7);
    border-radius: 100vh;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(203, 213, 225, 1);
  }

  .scrollbar-0::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .scrollbar-1::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }
  .scrollbar-1::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
  .scrollbar-1::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  --swiper-theme-color: #17b193;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-inactive-color: #cbd5e1;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

*,
*:focus {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Archivo", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
}

swiper-container.details-image {
  --swiper-navigation-size: 24px;
  --swiper-navigation-sides-offset: 0px;
  --swiper-pagination-bottom: 4px;
}

.quillcss .ql-container.ql-snow{
  height: 163px;
  margin: 0 0 33px;
}
.quillcss .quill{
  margin: 60px 0 0;
}


/* ============================================================================================ */

/* @import url("https://fonts.googleapis.com/css?family=Libre+Franklin:100,100i,200,200i,300,300i,400,500,500i,600,600i,700,700i,800,800i,900,900i|Playfair+Display:400,400i,700,700i,900,900i|Abril+Fatface&amp;subset=latin-ext"); */

* {
  box-sizing: border-box;
}

/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Libre Franklin", sans-serif;
  color: #747474;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: 0;
}

h1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
}

h2 {
  font-size: 36px;
  line-height: 36px;
}

h3 {
  font-size: 30px;
  line-height: 30px;
}

h4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

h5 {
  font-size: 14px;
  line-height: 18px;
}

h6 {
  font-size: 12px;
  line-height: 14px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s;
}

.btn:focus {
  box-shadow: none;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*--- Common Classes -----------------------------------------*/

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    width: 100%;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

/*--- Background color -----------------------------------------*/
.bg-white {
  background-color: #ffffff;
}

.bg-light-grey {
  background-color: #f6fafb;
}

.bg-grey {
  background-color: #f8f8f8;
}

.bluewood-bg {
  background: #354b65;
}

/*- Overlay Color BG -*/
.bluewood-bg-overlay {
  position: relative;
}

.bluewood-bg-overlay::before {
  background: rgba(70, 90, 112, 0.9);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overly-bg-black {
  position: relative;
}

.overly-bg-black::after {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/*--- color -----------------------------------------*/
/*--- Input Placeholder -----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*-- Scroll Up -----------------------------------------*/
#scrollUp {
  background: #242424 none repeat scroll 0 0;
  bottom: 85px;
  color: #ffffff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  height: 45px;
  line-height: 39px;
  position: fixed;
  right: 12px;
  text-align: center;
  width: 45px;
  z-index: 9999;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

/*--- Main Wrapper -----------------------------------------*/
.main-wrapper.header-transparent {
  padding: 0 !important;
  margin: 0 !important;
}

.social ol li {
  list-style: none;
}

.social ol li a {
  background: #c08b47;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  padding: 2px 0 0;
  margin: 0 5px 0 0;
  display: block;
  color: #fff;
  font-size: 15px;
  border: 1px solid transparent;
}

.social ol li a:hover {
  border: 1px solid #c08b47;
  background: transparent;
}

.fa-phone {
  color: #be8849;
  font-size: 18px;
}

/*--- Section Padding -------------------------------------*/
.section-ptb {
  padding: 100px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-ptb {
    padding: 90px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-ptb {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-ptb {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 479px) {
  .section-ptb {
    padding: 60px 0;
  }
}

.section-pt {
  padding-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt {
    padding-top: 60px;
  }
}

.section-pt-70 {
  padding-top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt-70 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt-70 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt-70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt-70 {
    padding-top: 30px;
  }
}

.section-pb-70 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb-70 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb-70 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb-70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb-70 {
    padding-bottom: 30px;
  }
}

.section-pb {
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

.categoryarea {
  padding: 0 120px 54px;
}

.item-shopcat19 {
  border: 1px solid #e5e5e5;
  margin-bottom: 30px;
  transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
  text-align: center;
}

.item-shopcat19>a {
  display: block;
}

.item-shopcat19 .cat-title {
  border-top: 1px solid #e5e5e5;
  color: #333;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 15px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
}

/* 02. Element */
.button {
  background: #bd874a;
  border-radius: 16px;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  height: 33px;
  line-height: 33px;
  padding: 0 16px;
  text-transform: uppercase;
  font-size: 12px;
}

/* 2. Home 1 Header css here */
/* 03. header css here */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_area {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_area {
    padding: 20px 0;
  }
}

.header_bottom {
  background: #f7f7f7;
}

.sticky-header.sticky .main_menu nav>ul>li.active>a {
  color: #fff;
}

.sticky-header.sticky .main_menu nav>ul>li:hover>a {
  color: #fff;
}

.sticky-header.sticky {
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  background: rgb(193 140 71 / 97%);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 900ms;
  animation-duration: 900ms;
  -webkit-animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky-header.sticky {
    position: inherit;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    -webkit-animation-name: inherit;
    animation-name: inherit;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sticky-header.sticky {
    position: inherit;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    -webkit-animation-name: inherit;
    animation-name: inherit;
    padding: 0;
  }
}

.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #747474;
  top: 0;
}

.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.offcanvas_menu {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_menu {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas_menu {
    display: block;
  }
}

.offcanvas_menu .container {
  position: relative;
}

.offcanvas_menu_wrapper {
  width: 290px;
  position: fixed;
  background: #fff;
  z-index: 99;
  top: 0;
  height: 100vh;
  transition: .5s;
  left: 0;
  margin-left: -300px;
  padding: 50px 20px 30px;
  overflow-y: auto;
}

.offcanvas_menu_wrapper.active {
  margin-left: 0;
}

.offcanvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}

.offcanvas_menu_wrapper .welcome_text {
  display: block;
}

.offcanvas_menu_wrapper .welcome_text ul li {
  color: #242424;
  line-height: 20px;
}

.offcanvas_menu_wrapper .welcome_text ul li span {
  color: #242424;
}

.offcanvas_menu_wrapper .top_right {
  display: block;
}

.offcanvas_menu_wrapper .top_right>ul>li {
  padding-left: 10px;
  margin-left: 10px;
}

.offcanvas_menu_wrapper .top_right>ul>li>a {
  color: #242424;
  font-size: 11px;
}

.offcanvas_menu_wrapper .search_bar {
  display: block;
}

.offcanvas_menu_wrapper .cart_area {
  display: flex;
}

.offcanvas_menu_wrapper .contact_phone {
  display: block;
  margin-top: 0;
  margin-bottom: 28px;
}

.offcanvas_menu_wrapper .contact_phone p {
  line-height: 17px;
}

.offcanvas_main_menu>li.menu-item-has-children.menu-open>span.menu-expand {
  transform: rotate(180deg);
}

.offcanvas_main_menu>li ul li.menu-item-has-children.menu-open span.menu-expand {
  transform: rotate(180deg);
}

.offcanvas_main_menu li {
  position: relative;
}

.offcanvas_main_menu li:last-child {
  margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}

.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.offcanvas_main_menu li a:hover {
  color: #bd874a;
}

.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}

.offcanvas_footer {
  margin-top: 30px;
  padding-bottom: 50px;
  text-align: center;
}

.offcanvas_footer span a {
  font-size: 14px;
}

.offcanvas_footer span a:hover {
  color: #bd874a;
}

.offcanvas_footer ul {
  margin-top: 20px;
}

.offcanvas_footer ul li {
  display: inline-block;
  margin-right: 4px;
}

.offcanvas_footer ul li:last-child {
  margin-right: 0;
}

.offcanvas_footer ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-size: 13px;
  color: #ffffff;
}

.offcanvas_footer ul li a {
  background: #c48f45;
}

.ec-services-section {
  padding: 0 0 86px;
}

.ec_ser_inner {
  padding: 30px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: 1px solid #eeeeee;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}

.ec_ser_inner .ec-service-image {
  margin-bottom: 15px;
}

.ec-service-desc h2 {
  font-size: 18px;
  font-weight: 700;
  color: #444444;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
}

.ec-service-desc p {
  font-size: 14px;
  color: #777777;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.slinky-theme-default a:not(.back) {
  padding: 10px 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

.slinky-theme-default a:not(.back):hover {
  background: inherit;
  color: #bd874a;
}

.canvas_close {
  position: absolute;
  top: 10px;
  right: 13px;
}

.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 32px;
  height: 32px;
  display: block;
  text-align: center;
  line-height: 31px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.canvas_close a:hover {
  background: #bd874a;
  border-color: #bd874a;
  color: #fff;
}

.canvas_open {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .canvas_open {
    right: 20px;
    top: 24px;
  }
}

.canvas_open a {
  font-size: 30px;
  color: #747474;
  width: 43px;
  height: 41px;
  display: block;
  line-height: 40px;
  text-align: center;
  border: 1px solid #747474;
  border-radius: 3px;
}

@media only screen and (max-width: 767px) {
  .canvas_open a {
    width: 35px;
    height: 34px;
    line-height: 34px;
    font-size: 24px;
  }
}

.canvas_open a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_two .canvas_open {
    right: 33px;
  }
}

.offcanvas_four .canvas_open a {
  color: #fff;
  border: 1px solid #fff;
}

.offcanvas_four .canvas_open a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

.header_top {
  background: #242424;
  padding: 7px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_top {
    padding: 7px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_top {
    padding: 12px 0;
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_top {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_text {
    display: none;
    text-align: center;
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome_text {
    display: none;
    margin-bottom: 13px;
  }
}

.welcome_text ul li {
  display: inline-block;
  font-size: 12px;
  color: #a9a9a9;
  line-height: 26px;
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .welcome_text ul li {
    font-size: 11px;
    padding-right: 12px;
    margin-right: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_text ul li {
    font-size: 11px;
    padding-right: 0;
    margin-right: 0;
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .welcome_text ul li {
    font-size: 11px;
    padding-right: 0;
    margin-right: 0;
    display: block;
    text-align: center;
  }
}

.welcome_text ul li::before {
  content: "";
  height: 16px;
  width: 1px;
  background: #a9a9a9;
  position: absolute;
  top: 7px;
  right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_text ul li::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .welcome_text ul li::before {
    display: none;
  }
}

.welcome_text ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.welcome_text ul li:last-child::before {
  display: none;
}

.welcome_text ul li span {
  color: #fff;
}

.top_right {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top_right {
    text-align: center !important;
    display: none;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .top_right {
    text-align: center !important;
    display: none;
    margin-bottom: 18px;
  }
}

.top_right>ul>li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .top_right>ul>li {
    padding-left: 14px;
    margin-left: 14px;
  }
}

.top_right>ul>li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.top_right>ul>li::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  background: #999;
  top: 6px;
  left: 0px;
}

.top_right>ul>li:first-child::before {
  display: none;
}

.top_right>ul>li>a {
  color: #fff;
  text-transform: capitalize;
  line-height: 26px;
  font-size: 12px;
  cursor: pointer;
  display: block;
  font-weight: 400;
}

.top_right>ul>li>a i {
  margin-left: 5px;
  font-size: 15px;
  color: #be8849;
  pointer-events: none;
}

.top_right>ul>li>a:hover {
  color: #bd874a;
}

.top_right>ul>li>a:not([href]):not([tabindex]) {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top_right>ul>li>a:not([href]):not([tabindex]) {
    color: #242424;
  }
}

@media only screen and (max-width: 767px) {
  .top_right>ul>li>a:not([href]):not([tabindex]) {
    color: #242424;
  }
}

.top_right>ul>li:hover a:not([href]):not([tabindex]) {
  color: #bd874a;
}

.dropdown_currency,
.dropdown_language,
.dropdown_links {
  position: absolute;
  background: #fff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  min-width: 140px;
  text-align: center;
  top: 127%;
  max-height: 0;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  overflow: hidden;
  right: 0;
  z-index: 9999;
  padding: 0 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .dropdown_currency,
  .dropdown_language,
  .dropdown_links {
    right: inherit;
    min-width: 90px;
    padding: 0 6px;
    right: 0;
    left: auto;
  }
}

@media only screen and (max-width: 767px) {

  .dropdown_currency,
  .dropdown_language,
  .dropdown_links {
    right: inherit;
    min-width: 90px;
    padding: 0 6px;
    right: 0;
    left: auto;
  }
}

.dropdown_currency li {
  border-bottom: 1px solid #ddd;
  line-height: 36px;
}

.dropdown_currency li:last-child {
  border-bottom: 0;
}

.dropdown_currency li a {
  text-transform: capitalize;
  display: block;
  font-size: 12px;
  color: #747474;
  text-align: left;
}

.dropdown_currency li a:hover {
  color: #bd874a;
}

.dropdown_language li {
  border-bottom: 1px solid #ddd;
  line-height: 36px;
}

.dropdown_language li:last-child {
  border-bottom: 0;
}

.dropdown_language li a {
  text-transform: capitalize;
  display: block;
  font-size: 12px;
  color: #747474;
  text-align: left;
}

.dropdown_language li a:hover {
  color: #bd874a;
}

.dropdown_links li {
  border-bottom: 1px solid #ddd;
  line-height: 36px;
}

.dropdown_links li:last-child {
  border-bottom: 0;
}

.dropdown_links li a {
  text-transform: capitalize;
  display: block;
  font-size: 12px;
  color: #747474;
  text-align: left;
}

.dropdown_links li a:hover {
  color: #bd874a;
}

.dropdown_currency.open,
.dropdown_language.open,
.dropdown_links.open {
  max-height: 150px;
}

.top_right ul li a img {
  margin-right: 5px;
}

/*header top css end*/
/* 2.2 header middel css here */
.header_middel {
  padding: 13px 0 18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_middel {
    padding: 40px 30px 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_middel {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_middel {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_bar {
    margin-bottom: 24px;
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .search_bar {
    margin-bottom: 24px;
    display: none;
  }
}

.search_bar form {
  width: 540px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search_bar form {
    width: 435px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_bar form {
    width: 100%;
    flex-direction: column;
    border: 0;
    border-radius: 0;
  }
}

@media only screen and (max-width: 767px) {
  .search_bar form {
    width: 100%;
    flex-direction: column;
    border: 0;
    border-radius: 0;
  }
}

.search_bar form .select_option {
  border: 0;
  background: #f6f6f6;
  border-right: 1px solid #ddd;
  height: 48px;
  border-radius: 0;
  line-height: 48px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_bar form .select_option {
    height: 39px;
    line-height: 39px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 0;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .search_bar form .select_option {
    height: 39px;
    line-height: 39px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 0;
    margin-bottom: 25px;
  }
}

.search_bar form .select_option span {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color: #555;
}

.search_bar form .select_option ul.list {
  max-height: 200px;
  overflow: auto;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_bar form .select_option ul.list {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .search_bar form .select_option ul.list {
    width: 100%;
  }
}

.search_bar form input {
  height: 48px;
  line-height: 50px;
  padding: 0px 70px 0 20px !important;
  width: 100%;
  border: 0;
  background: transparent;
  color: #a4a4a4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_bar form input {
    height: 40px;
    line-height: 40px;
    padding: 0px 40px 0 15px !important;
    width: 100%;
    border: 1px solid #ddd;
  }
}

@media only screen and (max-width: 767px) {
  .search_bar form input {
    height: 40px;
    line-height: 40px;
    padding: 0px 40px 0 15px !important;
    width: 100%;
    border: 1px solid #ddd;
  }
}

.search_bar form button {
  background: inherit;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  border: 0;
  font-size: 22px;
  width: 50px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  line-height: 52px;
  color: #747474;
}

.search_bar form button:hover {
  color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_bar form button {
    line-height: 44px;
    width: 41px;
    font-size: 20px;
    bottom: 2px;
    top: auto;
    height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .search_bar form button {
    line-height: 44px;
    width: 41px;
    font-size: 20px;
    bottom: 2px;
    top: auto;
    height: 40px;
  }
}

.cart_area {
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart_area {
    justify-content: center;
    display: none;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .cart_area {
    justify-content: center;
    display: none;
    margin-bottom: 18px;
  }
}

.middel_links ul li {
  display: inline-block;
  padding-right: 8px;
}

.middel_links ul li:last-child {
  padding-right: 0;
}

.middel_links ul li a {
  color: #242424;
  font-size: 12px;
  line-height: 28px;
}

.middel_links ul li a:hover {
  color: #bd874a;
}

.cart_link>a:hover {
  color: #bd874a;
}

.cart_link {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart_link {
    margin-left: 15px;
  }
}

.cart_link:hover .mini_cart {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.cart_link>a {
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  color: #747474;
  border: 1px solid #ddd;
  padding: 10px 17px 8px;
  border-radius: 30px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart_link>a {
    padding: 6px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .cart_link>a {
    padding: 6px 13px;
  }
}

.cart_link>a i {
  font-size: 17px;
  margin-right: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart_link>a i {
    font-size: 14px;
    margin-right: 4px;
  }
}

.mini_cart {
  position: absolute;
  width: 345px;
  background: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  right: 0;
  padding: 30px 20px 30px;
  top: 120%;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mini_cart {
    display: none;
    top: 100%;
    opacity: inherit;
    visibility: inherit;
    transition: unset;
    width: 260px;
    right: -11px;
  }
}

@media only screen and (max-width: 767px) {
  .mini_cart {
    top: 100%;
    display: none;
    width: 260px;
    right: -11px;
    opacity: inherit;
    visibility: inherit;
    transition: unset;
  }
}

.cart_img {
  width: 30%;
  float: left;
  margin-right: 15px;
}

.cart_info {
  width: 60%;
  float: right;
}

.cart_info a {
  font-weight: 500;
  color: #747474;
}

.cart_info a:hover {
  color: #bd874a;
}

.cart_info span {
  display: block;
}

.cart__table {
  padding: 19px 0;
}

.cart__table table {
  width: 100%;
}

.cart__table table tbody tr:last-child td {
  padding-bottom: 0;
}

.cart__table table tbody tr:last-child td:last-child {
  color: #bd874a;
  font-size: 16px;
}

.cart__table table tbody tr td {
  color: #242424;
  text-transform: capitalize;
  font-size: 14px;
  padding-bottom: 6px;
}

.cart__table table tbody tr td:last-child {
  font-weight: 600;
}

.cart_remove {
  width: 10%;
  text-align: right;
}

.cart_remove a {
  font-size: 20px;
  color: #747474;
}

.cart_remove a:hover {
  color: #bd874a;
}

.cart_item {
  overflow: hidden;
  padding: 25px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
}

.cart_item.top {
  padding-top: 0;
}

.cart_button a {
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 500;
  color: #242424;
  background: #f6f6f6;
  display: block;
  text-align: center;
  line-height: 20px;
  margin-bottom: 0;
  padding: 11px 0px 9px;
  font-size: 13px;
  border: 1px solid #ddd;
}

.cart_button a:hover {
  background: #242424;
  border-color: #242424;
  color: #fff;
}

.cart_button.view_cart {
  padding-bottom: 25px;
}

/*header middel css end*/
/* 2.4 main menu css here */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom {
    max-height: 0;
  }
}

.header_static {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_static {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .header_static {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main_menu_inner {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .main_menu_inner {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main_menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main_menu {
    display: none;
  }
}

.main_menu nav>ul>li {
  display: inline-block;
  position: relative;
}

.main_menu nav>ul>li.mega_items {
  position: static;
}

.main_menu nav>ul>li>a {
  display: block;
  padding: 15px 25px;
  color: #242424;
  font-size: 14px;
  line-height: 26px;
  text-transform: capitalize;
  font-weight: 500;
}

.main_menu nav>ul>li>a i {
  margin-left: 2px;
}

.main_menu nav>ul>li:hover>a {
  color: #bd874a;
}

.main_menu nav>ul>li.active>a {
  color: #bd874a;
}

.main_menu nav>ul>li:first-child a {
  padding-left: 0;
}

.main_menu nav>ul>li ul.sub_menu {
  position: absolute;
  min-width: 220px;
  padding: 30px 25px;
  background: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 9;
  top: 140%;
}

.main_menu nav ul li:hover .sub_menu,
.main_menu nav ul li:hover .mega_menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.main_menu nav ul li ul li.active a,
.main_menu nav ul li ul li a:hover {
  color: #bd874a;
}

.main_menu nav>ul>li>ul>li>a {
  color: #444444;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 18px;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}

.main_menu nav>ul>li>ul.sub_menu>li:last-child>a {
  margin-bottom: 0;
}

.main_menu nav>ul>li>ul.mega_menu li ul li:last-child a {
  margin-bottom: 0;
}

.main_menu nav ul li ul.mega_menu {
  position: absolute;
  min-width: 100%;
  padding: 30px 25px 35px;
  background: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 9;
  top: 140%;
}

.main_menu nav>ul>li>ul.mega_menu>li {
  width: 25%;
  float: left;
  border-left: 1px solid #ddd;
  padding-left: 30px;
}

.main_menu nav>ul>li>ul.mega_menu>li:first-child {
  border-left: 0;
  padding-left: 0;
}

.main_menu nav>ul>li>ul.mega_menu>li:last-child {
  border-left: 0;
  padding-left: 0;
}

.main_menu nav>ul>li>ul.mega_menu>li.banner_menu {
  width: 100%;
  margin-top: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.main_menu nav>ul>li>ul.mega_menu>li.banner_menu:hover {
  opacity: 0.8;
}

.main_menu nav>ul>li>ul.mega_menu>li.banner_menu a {
  margin-bottom: 0;
}

.main_menu nav>ul>li>ul.mega_menu>li>a {
  text-transform: capitalize;
  color: #242424;
  font-weight: 600;
  font-size: 14px;
}

.main_menu nav>ul>li>ul.mega_menu li ul li a {
  color: #747474;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 15px;
  display: block;
  text-transform: capitalize;
  font-weight: 400;
}

.main_menu nav>ul>li>ul.mega_menu li ul li a:hover {
  color: #bd874a;
}

.main_menu nav ul li ul.sub_menu.pages {
  left: inherit;
  right: inherit;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_bottom {
    padding: 0 30px;
  }
}

.contact_phone {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_phone {
    text-align: center;
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .contact_phone {
    text-align: center;
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact_phone p {
    font-size: 11px;
  }
}

.contact_phone p a:hover {
  color: #bd874a;
}

/*main menu css end*/
/*mobaile menu css here*/
.mean-container .mean-bar {
  background: inherit;
  position: absolute;
  z-index: 9;
  top: 43px;
  left: 0;
  width: 100%;
}

.mean-container .mean-bar::after {
  top: -33px;
  left: 65px;
}

@media only screen and (max-width: 767px) {
  .mean-container .mean-bar::after {
    left: 9%;
    font-size: 15px;
  }
}

.mean-container .mean-nav {
  max-height: 300px;
  overflow: auto;
}

.mean-container a.meanmenu-reveal {
  float: left;
  right: inherit;
  top: -34px;
  left: 20px;
}

@media only screen and (max-width: 767px) {
  .mean-container a.meanmenu-reveal {
    float: right;
    right: 15px;
    top: -34px;
    left: auto;
  }
}

.mobile-menu {
  border: 1px solid #ddd;
  height: 45px;
  top: -31px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .mobile-menu {
    top: 5px;
  }
}

/*home two css here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_two {
    padding: 30px 15px;
  }
}

.header_two .sticky-header.sticky {
  background: rgba(36, 36, 36, 0.7);
}

.header_two .sticky-header.sticky .main_menu_inner {
  background: inherit;
}

@media only screen and (max-width: 767px) {
  .header_two .sticky-header.sticky {
    background: inherit;
  }
}

.header_two .welcome_text ul li::before {
  background: #555;
}

.header_two .top_right>ul>li::before {
  background: #555;
}

.header_two .main_menu_inner {
  text-align: center;
  background: #242424;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_two .main_menu_inner {
    background: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .header_two .main_menu_inner {
    background: inherit;
  }
}

.header_two .main_menu nav>ul>li ul.sub_menu {
  text-align: left;
}

.header_two .main_menu nav>ul>li ul.mega_menu {
  text-align: left;
  left: 15px;
  right: 15px;
  min-width: 97%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_two .main_menu nav>ul>li ul.mega_menu {
    min-width: 580px;
  }
}

.header_two .main_menu nav>ul>li:first-child>a {
  padding: 15px 25px;
}

.header_two .main_menu nav>ul>li>a {
  color: #fff;
}

.header_two .header_middel {
  padding-bottom: 39px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_two .header_middel {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_two .header_middel {
    padding-bottom: 0;
  }
}

.header_two .header_bottom {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_two .header_bottom {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_two .header_bottom {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile_two.mean-container a.meanmenu-reveal {
    float: right;
    right: 15px;
    top: -34px;
    left: auto;
  }
}

.mobile_two.mean-container .mean-bar::after {
  left: 5%;
}

/*home three css heer*/
.header_three .header_top {
  padding: 7px 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .header_top {
    padding: 7px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .header_top {
    padding: 7px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .header_top {
    padding: 7px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .header_top {
    padding: 7px 0;
  }
}

.header_three .header_middel {
  padding: 40px 30px 18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .header_middel {
    padding: 40px 30px 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .header_middel {
    padding: 40px 30px 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .header_middel {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .header_middel {
    padding: 0;
  }
}

.header_three .header_bottom {
  display: none;
}

.header_three .header_bottom.sticky-header.sticky {
  display: block;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .header_bottom {
    display: block;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .main_menu nav>ul>li ul.mega_menu {
    min-width: 580px;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .search_bar {
    margin-top: 18px;
    margin-bottom: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .search_bar {
    margin-top: 0;
  }
}

.header_three .search_bar form {
  width: 274px;
}

.header_three .search_bar form input {
  width: 100%;
  height: 46px;
  line-height: 48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .search_bar form {
    width: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .search_bar form {
    width: 280px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .search_bar form {
    width: 254px;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .search_bar form {
    width: 100%;
  }
}

.header_three .logo {
  text-align: center;
  display: none;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_three .logo {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .logo {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .logo {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .logo {
    position: unset;
    display: block;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .logo {
    display: block;
    text-align: left;
  }
}

.header_three .header_middel {
  padding-bottom: 0;
  position: relative;
}

.header_three .middel_inner {
  padding-bottom: 40px;
  border-bottom: 0;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_three .middel_inner {
    border-bottom: 1px solid #ddd;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .middel_inner {
    border-bottom: 1px solid #ddd;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .middel_inner {
    border-bottom: 1px solid #ddd;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .middel_inner {
    border-bottom: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .middel_inner {
    border-bottom: 0;
    padding: 0;
  }
}

.header_three .main_menu_inner {
  text-align: center;
}

.header_three .main_menu nav>ul>li:first-child>a {
  padding: 15px 25px;
}

.header_three .main_menu nav>ul>li ul.sub_menu li {
  text-align: left;
}

.header_three .main_menu nav>ul>li ul.mega_menu li {
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .cart_area {
    position: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .cart_link {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .cart_link>a {
    padding: 7px 8px;
  }
}

.header_three .cart_link>a i {
  font-size: 14px;
  margin-right: 5px;
}

.mobile_three {
  top: -11px;
}

@media only screen and (max-width: 767px) {
  .mobile_three {
    top: -15px;
  }
}

.mobile_three.mean-container a.meanmenu-reveal {
  float: right;
  right: 15px;
  top: -34px;
  left: auto;
}

.mobile_three.mean-container .mean-bar::after {
  left: 5%;
}

/*home four css here*/
.header_transparent {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_transparent {
    background: #242424;
    position: inherit;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_transparent {
    background: #242424;
    position: inherit;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_transparent {
    position: inherit;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_transparent {
    background: #242424;
    position: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .header_transparent {
    background: #242424;
    position: inherit;
  }
}

.header_four .welcome_text ul li::before {
  background: #393939;
}

.header_four .top_right>ul>li::before {
  background: #393939;
}

.header_four .sticky-header.sticky {
  background: rgba(36, 36, 36, 0.7);
}

.header_four .sticky-header.sticky .header_bottom {
  background: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .sticky-header.sticky {
    background: #242424;
  }
}

@media only screen and (max-width: 767px) {
  .header_four .sticky-header.sticky {
    background: #242424;
  }
}

.header_four .header_top {
  border-bottom: 1px solid #393939;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .header_four .header_top {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .search_bar {
    margin-top: 0;
  }
}

.header_four .search_bar form {
  border: 1px solid #393939;
  width: 274px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_four .search_bar form {
    width: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_four .search_bar form {
    width: 280px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .search_bar form {
    width: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .header_four .search_bar form {
    width: 100%;
  }
}

.header_four .search_bar form input {
  color: #5b5a5a;
  height: 46px;
  line-height: 48px;
}

@media only screen and (max-width: 767px) {
  .header_four .search_bar form input {
    width: 100%;
  }
}

.header_four .search_bar form button {
  color: #fff;
}

.header_four .search_bar form button:hover {
  color: #bd874a;
}

.header_four .logo {
  text-align: center;
  display: none;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_four .logo {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_four .logo {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_four .logo {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .logo {
    display: block;
    position: unset;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .header_four .logo {
    display: block;
    text-align: left;
  }
}

.header_four .main_menu_inner {
  text-align: center;
}

.header_four .main_menu nav>ul>li:first-child>a {
  padding: 15px 25px;
}

.header_four .main_menu nav>ul>li>a {
  color: #fff;
}

.header_four .main_menu nav>ul>li ul.sub_menu li {
  text-align: left;
}

.header_four .main_menu nav>ul>li ul.mega_menu li {
  text-align: left;
}

.header_four .header_middel {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .header_middel {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_four .header_middel {
    padding-left: 0;
    padding-right: 0;
  }
}

.header_four .middel_links ul li a {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .cart_link {
    margin-left: 15px;
  }
}

.header_four .cart_link>a {
  color: #fff;
  border: 1px solid #393939;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .cart_link>a {
    padding: 7px 11px;
  }
}

.header_four .cart_link>a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

.header_four .header_bottom {
  display: none;
}

.header_four .header_bottom.sticky-header.sticky {
  display: block;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_four .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_four .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_four .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .header_bottom {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header_four .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_four .cart_area {
    position: none;
    position: unset;
  }
}

.mobile_four {
  top: -73px;
}

@media only screen and (max-width: 767px) {
  .mobile_four {
    top: -26px;
  }
}

.mobile_four.mean-container .mean-bar {
  top: 44px;
}

.mobile_four.mean-container .mean-bar::after {
  color: #fff;
  left: 4%;
  top: -34px;
}

.mobile_four.mean-container a.meanmenu-reveal {
  right: 15px;
  top: -35px;
  left: auto;
  color: #fff;
}

.mobile_four.mean-container a.meanmenu-reveal span {
  background: #fff;
}

.horizontal_menu {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  left: 20%;
  top: 37px;
  z-index: 999;
  max-width: 1190px;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .horizontal_menu {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .horizontal_menu {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .horizontal_menu {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .horizontal_menu {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .horizontal_menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .horizontal_menu {
    display: none;
  }
}

.horizontal_menu .logo_container {
  padding: 0 100px;
}

.horizontal_menu .left_menu .main_menu nav>ul>li:first-child>a {
  padding-left: 6px;
}

.horizontal_menu .main_menu nav>ul>li:first-child>a {
  padding: 15px 24px;
}

.horizontal_menu .main_menu nav>ul>li>a {
  padding: 15px 24px;
}

/*home six css here*/
.header_six .search_bar form {
  width: 100%;
}

.header_six .top_right>ul>li:hover>a {
  color: #bd874a;
}

.header_six .top_right>ul>li>a {
  color: #242424;
}

.header_six .top_right>ul>li>a i.ion-android-person {
  font-size: 15px;
  margin-left: 0;
  margin-right: 3px;
}

.header_six .top_right>ul>li>a:not([href]):not([tabindex]) {
  color: #242424;
}

.header_six .top_right>ul>li>a:not([href]):not([tabindex]):hover {
  color: #bd874a;
}

.header_six .main_menu_inner {
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_six .canvas_open {
    right: 33px;
    top: 27px;
  }
}

/*homr six css here*/
.header_six .header_middel {
  padding: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_six .header_middel {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .header_middel {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .header_middel {
    padding-top: 0;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_six .header_middel {
    padding: 0;
  }
}

.header_six .header_bottom {
  display: none;
}

.header_six .header_bottom.sticky-header.sticky {
  display: block;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_six .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_six .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .header_bottom {
    display: block;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_six .header_bottom {
    display: block;
  }
}

.header_six .middel_inner {
  border-bottom: 0;
  padding: 43px 0;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_six .middel_inner {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_six .middel_inner {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .middel_inner {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .middel_inner {
    padding: 0;
    border-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_six .middel_inner {
    padding: 0;
    border-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .logo {
    position: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .search_bar {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_six .search_bar {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .search_bar form input {
    height: 39px;
    line-height: 29px;
    padding: 0px 46px 0 20px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .search_bar form input {
    height: 43px;
    line-height: 39px;
  }
}

@media only screen and (max-width: 767px) {
  .header_six .search_bar form input {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .search_bar form button {
    line-height: 42px;
    width: 43px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .search_bar form button {
    line-height: 42px;
    width: 43px;
  }
}

.header_six .main_menu nav>ul>li ul.sub_menu li {
  text-align: left;
}

.header_six .main_menu nav>ul>li ul.mega_menu {
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .top_right {
    text-align: center !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .top_right {
    text-align: left !important;
  }
}

@media only screen and (max-width: 767px) {
  .header_six .top_right {
    text-align: center !important;
    margin-bottom: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .top_right>ul>li {
    padding-left: 11px;
    margin-left: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .top_right>ul>li {
    padding-left: 8px;
    margin-left: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .top_right>ul>li:first-child {
    padding-left: 0;
    margin-left: 0;
  }
}

.header_six .top_right>ul>li ul {
  right: inherit;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .header_six .top_right>ul>li ul {
    left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .cart_area {
    position: unset;
    margin-top: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_six .cart_link {
    margin-left: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .cart_link {
    margin-left: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_six .cart_link>a {
    padding: 6px 8px;
  }
}

.mobile_six {
  top: 29px;
}

@media only screen and (max-width: 767px) {
  .mobile_six {
    top: 24px;
  }
}

.mobile_six.mean-container a.meanmenu-reveal {
  float: right;
  right: 15px;
  left: auto;
}

.mobile_six.mean-container .mean-bar::after {
  top: -33px;
  left: 4%;
}

.middel_right_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_right_info {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .middel_right_info {
    flex-direction: column;
    display: none;
  }
}

.middel_right_info .cart_link {
  margin-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_right_info .cart_link {
    margin-left: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .middel_right_info .cart_link {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .middel_right_info .mini_cart {
    right: -88px;
  }
}

.middel_right_info .search_bar {
  border-right: 1px solid #ddd;
  margin-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_right_info .search_bar {
    margin-right: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .middel_right_info .search_bar {
    border-right: 0;
    margin-right: 0;
  }
}

.middel_right_info .search_bar form {
  border: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_right_info .search_bar form {
    width: 200px;
  }
}

.middel_right_info .search_bar form button {
  color: #242424;
  line-height: 38px;
}

.middel_right_info .search_bar form button:hover {
  color: #bd874a;
}

.middel_right_info .search_bar form input {
  height: 35px;
  line-height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_right_info .search_bar form input {
    padding: 0px 45px 0 20px !important;
  }
}

.horizontal_menu.horizontal_menu_six {
  display: inherit;
  left: 279px;
  right: inherit;
  top: 39px;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .horizontal_menu.horizontal_menu_six {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .horizontal_menu.horizontal_menu_six {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .horizontal_menu.horizontal_menu_six {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .horizontal_menu.horizontal_menu_six {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .horizontal_menu.horizontal_menu_six {
    display: none;
  }
}

.horizontal_menu.horizontal_menu_six .main_menu nav>ul>li ul.mega_menu {
  min-width: 120%;
}

.horizontal_menu.horizontal_menu_six .main_menu nav>ul>li>a {
  padding: 15px 26px;
}

/*page menu css here*/
.page_menu {
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translatey(-50%);
  z-index: 9;
}

.page_menu nav ul li {
  width: 20px;
  height: 20px;
  text-align: center;
}

.page_menu nav ul li.current a {
  width: 15px;
  height: 15px;
}

.page_menu nav ul li a {
  width: 5px;
  height: 5px;
  background: #242424;
  border-radius: 50%;
}

/*home seven css here*/
.offcanvas_seven .canvas_open {
  z-index: 99;
  right: 35px;
}

.sticky-header.sticky .home_menu_inner {
  top: 159%;
}

.header_seven {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0;
  padding: 15px 0;
}

.header_seven.sticky-header.sticky .home_menu_inner {
  top: 176%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven {
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven {
    position: unset;
  }
}

.header_seven .header_top_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .header_seven .header_top_right {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .search_bar {
    position: absolute;
    width: 97%;
    margin-top: 0;
    top: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven .search_bar {
    width: 100%;
    margin-bottom: 18px;
  }
}

.header_seven .search_bar form {
  width: 250px;
  border: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_seven .search_bar form {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven .search_bar form {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .search_bar form {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 0;
  }
}

.header_seven .search_bar form input {
  width: 100%;
  height: 42px;
  line-height: 43px;
  padding: 0px 47px 0 20px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .cart_link {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven .cart_link {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven .mini_cart {
    right: -82%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .icone_menu {
    top: -3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .logo {
    top: -6px;
  }
}

.header_seven .top_right {
  margin-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_seven .top_right {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .top_right {
    position: relative;
    top: -36px;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven .top_right {
    margin-left: 0;
    margin-bottom: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_seven .top_right>ul>li {
    padding-left: 20px;
    margin-left: 20px;
  }
}

.header_seven .top_right>ul>li:first-child {
  padding-left: 0;
  margin-left: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_seven .top_right>ul>li {
    padding-left: 13px;
    margin-left: 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_seven .top_right>ul>li {
    padding-left: 15px;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .top_right>ul>li {
    padding-left: 15px;
    margin-left: 15px;
  }
}

.header_seven .top_right>ul>li>a {
  color: #242424;
}

.header_seven .top_right>ul>li>a:not([href]):not([tabindex]) {
  color: #242424;
}

.header_seven {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 47px;
}

.header_seven.sticky-header.sticky {
  padding-top: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_seven {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_seven {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven {
    padding: 20px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_seven .home_menu_bar {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven .home_menu_bar {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_seven .header_top_logo_wrapper {
    justify-content: flex-start;
    margin-bottom: 0;
  }
}

/*header eight css here*/
.header_eight .header_top {
  padding: 7px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .header_top {
    padding: 7px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .header_top {
    padding: 7px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_eight .header_top {
    padding: 7px 0;
  }
}

.header_eight .header_middel {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}

.header_eight .header_middel .middel_inner {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_eight .header_middel .middel_inner {
    padding-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_eight .header_middel .middel_inner {
    padding-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_eight .header_middel .middel_inner {
    padding-bottom: 18px;
    border-bottom: 1px solid #ddd;
    padding-top: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .header_middel .middel_inner {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_eight .header_middel .middel_inner {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .header_middel {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_eight .header_middel {
    padding-left: 0;
    padding-right: 0;
  }
}

.header_eight .header_middel .cart_link {
  margin-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_eight .header_middel .cart_link {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .header_middel .cart_link {
    margin-left: 10px;
  }
}

.header_eight .header_bottom {
  display: none;
}

.header_eight .header_bottom.sticky-header.sticky {
  display: block;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_eight .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_eight .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_eight .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .header_bottom {
    padding: 0 20px;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header_eight .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_eight .middel_links ul li {
    padding-right: 3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .cart_area {
    position: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .cart_link {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .cart_link>a {
    padding: 8px 7px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .search_bar {
    margin-top: 0;
  }
}

.header_eight .search_bar form {
  width: 274px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_eight .search_bar form {
    width: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_eight .search_bar form {
    width: 280px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .search_bar form {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .header_eight .search_bar form {
    width: 100%;
    border-radius: 0;
  }
}

.header_eight .search_bar form input {
  height: 46px;
  line-height: 48px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .search_bar form input {
    height: 41px;
    line-height: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .search_bar form button {
    line-height: 45px;
  }
}

.header_eight .logo {
  text-align: center;
  display: none;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .header_eight .logo {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_eight .logo {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_eight .logo {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_eight .logo {
    position: unset;
    display: block;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .header_eight .logo {
    display: block;
    text-align: left;
  }
}

.header_eight .main_menu_inner {
  text-align: center;
}

.header_eight .main_menu nav>ul>li {
  text-align: left;
}

.header_eight .main_menu nav>ul>li ul.mega_menu {
  width: 700px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_eight .canvas_open {
    right: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile_eight {
    top: 9px;
  }
}

@media only screen and (max-width: 767px) {
  .mobile_eight {
    top: 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile_eight a.meanmenu-reveal {
    float: right;
    right: 15px;
    top: -34px;
    left: auto;
  }
}

.mobile_eight .mean-bar::after {
  left: 5%;
}

.horizontal_menu_eight {
  top: 37px;
  left: 384px;
}

/*home five css here*/
.header_five {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}

.header_five .header_top {
  background: inherit;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_five .header_top {
    padding-top: 14px;
    padding-bottom: 15px;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header_five .header_top {
    padding-top: 21px;
    padding-bottom: 23px;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header_five .mini_cart {
    z-index: 99;
    right: -44px;
  }
}

.header_top_logo_wrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .header_top_logo_wrapper {
    justify-content: center;
    margin-bottom: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .header_top_logo_wrapper .logo {
    margin-bottom: 0;
  }
}

.home_menu_bar {
  margin-left: 70px;
}

.icone_menu {
  position: relative;
  top: 4px;
}

.icone_menu>a {
  font-size: 20px;
  color: #242424;
}

.icone_menu>a i {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.icone_menu>a i:hover {
  color: #bd874a;
}

.icone_menu>a:not([href]):not([tabindex]) i {
  color: #242424;
}

.icone_menu>a:not([href]):not([tabindex]) i:hover {
  color: #bd874a;
}

.home_menu_inner {
  position: absolute;
  width: 260px;
  max-height: 330px;
  background: #fff;
  border: 0;
  overflow: auto;
  padding: 0;
  max-height: 0;
  transition: all .3s ease-out;
  z-index: 9;
  top: 100%;
}

@media only screen and (max-width: 767px) {
  .home_menu_inner {
    right: -14px;
    width: 220px;
    z-index: 9999;
  }
}

.home_menu_inner.open {
  max-height: 450px;
  padding: 17px 0;
  border: 1px solid #e9e8e8;
}

@media only screen and (max-width: 767px) {
  .home_menu_inner.open {
    max-height: 350px;
  }
}

.home_menu_inner nav>ul>li:hover>a {
  color: #bd874a;
}

.home_menu_inner nav>ul>li>a {
  display: block;
  padding: 7px 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 500;
  font-size: 14px;
}

.home_menu_inner nav>ul>li>a i {
  float: right;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ddd;
}

.home_menu_inner nav>ul>li ul li {
  padding-left: 12px;
}

.home_menu_inner nav>ul>li ul li a {
  display: block;
  padding: 4px 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 400;
  font-size: 13px;
}

.home_menu_inner nav>ul>li ul li a:hover {
  color: #bd874a;
}

.home_menu_inner nav>ul>li ul.dropdown_home_menu {
  display: none;
}

.home_menu_inner nav>ul>li ul.dropdown_shop_menu {
  max-height: 0;
  transition: all .3s ease-out;
  background: #fff;
  z-index: 99;
  overflow: hidden;
}

.home_menu_inner nav>ul>li ul.dropdown_shop_menu.open {
  max-height: 150px;
}

.home_menu_inner nav>ul>li ul.dropdown_shop_menu li a i {
  float: right;
  margin-right: 25px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ddd;
}

.home_menu_inner nav>ul>li ul.dropdown_shop_menu li ul.dropdown_shop_items {
  display: none;
}

.home_menu_inner nav>ul>li ul.dropdown_shop_menu li ul.dropdown_shop_items2 {
  display: none;
}

.home_menu_inner nav>ul>li ul.dropdown_shop_menu li ul.dropdown_shop_items3 {
  display: none;
}

.home_menu_inner nav>ul>li ul.dropdown_blog_menu {
  display: none;
}

.home_menu_inner nav>ul>li ul.dropdown_other_page_menu {
  display: none;
}

.header_five {
  padding: 45px 30px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_five {
    padding: 0 30px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_five {
    padding: 0 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_five {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_five {
    padding: 0;
  }
}

.header_five.sticky-header.sticky {
  padding: 0 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_five .logo {
    position: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_five .cart_area {
    position: unset;
    display: flex;
    margin-bottom: 0;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 767px) {
  .header_five .cart_area {
    display: flex;
    margin-bottom: 0;
    justify-content: center;
  }
}

.header_five .middel_links>ul>li {
  padding-right: 20px;
  position: relative;
}

.header_five .middel_links>ul>li:last-child {
  padding-right: 0;
}

.header_five .middel_links>ul>li>a {
  color: #242424;
  font-size: 21px;
}

.header_five .middel_links>ul>li>a:hover {
  color: #bd874a;
}

.header_five .dropdown_search_btn {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.header_five .dropdown_search_btn.active {
  opacity: 1;
  visibility: visible;
}

.header_five .dropdown_search_btn form {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: 50%;
  width: 1000px;
  left: 0;
  right: 0;
  margin: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_five .dropdown_search_btn form {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_five .dropdown_search_btn form {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .header_five .dropdown_search_btn form {
    width: 90%;
  }
}

.header_five .dropdown_search_btn form input {
  width: 100%;
  border: 0;
  height: 70px;
  border-bottom: 3px solid #bd874a;
  background: inherit;
  color: #fff;
  font-size: 25px;
  padding: 0 60px 0 20px;
}

@media only screen and (max-width: 767px) {
  .header_five .dropdown_search_btn form input {
    height: 50px;
    font-size: 15px;
  }
}

.header_five .dropdown_search_btn form button {
  position: absolute;
  right: 0;
  background: inherit;
  border: 0;
  color: #fff;
  font-size: 39px;
  line-height: 70px;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .header_five .dropdown_search_btn form button {
    font-size: 20px;
    line-height: 50px;
  }
}

.header_five .dropdown_search_btn form button:hover {
  color: #bd874a;
}

.header_five .dropdown_search_btn .button_close {
  position: absolute;
  right: 6%;
  top: 70px;
}

.header_five .dropdown_search_btn .button_close button {
  border: 0;
  background: inherit;
  font-size: 70px;
  color: #fff;
}

.header_five .dropdown_search_btn .button_close button:hover {
  color: #bd874a;
}

.sticky-header.sticky .dropdown_box_setting {
  top: 157%;
}

.dropdown_box_setting {
  position: absolute;
  width: 280px;
  right: -23px;
  border: 1px solid #e9e8e8;
  background: #ffff;
  top: 100%;
  padding: 15px 20px;
  display: none;
  top: 100%;
}

@media only screen and (max-width: 767px) {
  .dropdown_box_setting {
    width: 275px;
    right: auto;
    left: -85px;
    top: 115%;
  }
}

.dropdown_box_setting .setting_list {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 16px;
}

.dropdown_box_setting .setting_list:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.dropdown_box_setting .setting_list h3 {
  font-size: 14px;
  color: #242424;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 24px;
}

.dropdown_box_setting .setting_list ul li {
  display: block;
}

.dropdown_box_setting .setting_list ul li a {
  font-size: 12px;
  color: #747474;
  line-height: 30px;
  display: block;
  text-transform: capitalize;
}

.dropdown_box_setting .setting_list ul li a:hover {
  color: #bd874a;
}

/*home nine css here*/
.header_middel9 {
  padding: 40px 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_middel9 {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_middel9 {
    padding: 0;
  }
}

.header_bottom9 {
  border-top: 1px solid #ddd;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom9 {
    border-top: 0;
    max-height: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .header_bottom9 {
    border-top: 0;
    max-height: inherit;
  }
}

.header_bottom9 .header_static {
  justify-content: flex-start;
}

/*-- 2.3 Categories menu css here --*/
.categories_menu {
  position: relative;
  width: 270px;
  margin-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu {
    margin-bottom: 0;
    width: 100%;
    margin-right: 0;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu {
    margin-bottom: 5px;
    width: 100%;
    margin-right: 0;
    margin-top: 18px;
  }
}

.categories_title {
  background: #bd874a;
  padding: 0 30px;
  position: relative;
  cursor: pointer;
  height: 56px;
  line-height: 56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_title {
    height: 45px;
    line-height: 46px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_title {
    height: 43px;
    line-height: 43px;
  }
}

.categories_title::before {
  content: "\f394";
  color: #fff;
  display: inline-block;
  font-family: Ionicons;
  position: absolute;
  font-size: 22px;
  line-height: 0px;
  right: 20px;
  top: 50%;
  transform: translatey(-50%);
}

.categories_title h2 {
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .categories_title h2 {
    font-size: 13px;
    line-height: 22px;
  }
}

.categories_menu_toggle {
  padding: 15px 0 9px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  display: none;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle {
    display: none;
    max-height: 350px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle {
    display: none;
    max-height: 350px;
    overflow: auto;
    padding: 0 11px 0 20px;
  }
}

.categories_menu_toggle>ul>li {
  position: relative;
}

.categories_menu_toggle>ul>li>a {
  font-size: 14px;
  line-height: 43px;
  text-transform: capitalize;
  font-weight: 400;
  display: block;
  cursor: pointer;
  padding: 0 20px 0 30px;
  color: #212121;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_menu_toggle>ul>li>a {
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a {
    line-height: 35px;
    padding: 0;
  }
}

.categories_menu_toggle>ul>li>a i.fa-angle-right {
  float: right;
  font-size: 15px;
  line-height: 35px;
  transition: .3s;
  -webkit-transition: .3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li>a i.fa-angle-right {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a i.fa-angle-right {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li>a:hover {
    color: #bd874a;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a:hover {
    color: #bd874a;
  }
}

.categories_menu_toggle>ul>li:last-child>a {
  border-bottom: 0;
}

.categories_menu_toggle>ul>li:hover ul.categories_mega_menu {
  opacity: 1;
  visibility: visible;
  left: 100%;
}

.categories_menu_toggle>ul>li:hover>a {
  color: #bd874a;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu {
  position: absolute;
  left: 120%;
  width: 720px;
  -webkit-box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 25px 20px 23px;
  overflow: hidden;
  top: 0;
  -webkit-transition: .3s;
  transition: .3s;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    width: 680px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100%;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    transition: unset;
    box-shadow: inherit;
    position: inherit;
    padding: 6px 20px 0px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100%;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    transition: unset;
    box-shadow: inherit;
    position: inherit;
    padding: 6px 20px 0px 40px;
  }
}

.categories_menu_toggle>ul>li ul.categories_mega_menu.open {
  display: block;
  left: 0;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li {
  padding: 0 15px;
  width: 25%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li {
    width: 100%;
    padding: 0;
  }
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li>a {
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
  color: #212121;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li>a:hover {
  color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li.menu_item_children>a {
    text-transform: capitalize;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li.menu_item_children>a {
    text-transform: capitalize;
    font-weight: 400;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li.menu_item_children>a {
    position: relative;
  }

  .categories_menu_toggle ul li.menu_item_children>a::before {
    position: absolute;
    content: "+";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li.menu_item_children>a {
    position: relative;
  }

  .categories_menu_toggle ul li.menu_item_children>a::before {
    position: absolute;
    content: "+";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li.menu_item_children.open>a::before {
    display: none;
  }

  .categories_menu_toggle ul li.menu_item_children.open>a::after {
    position: absolute;
    content: "-";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li.menu_item_children.open>a::before {
    display: none;
  }

  .categories_menu_toggle ul li.menu_item_children.open>a::after {
    position: absolute;
    content: "-";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub_menu {
    display: none;
    padding: 1px 0 9px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categorie_sub_menu {
    display: none;
    padding: 1px 0 9px 30px;
  }
}

.categorie_sub_menu li a {
  color: #212121;
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}

.categorie_sub_menu li a:hover {
  color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub_menu li a {
    line-height: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .categorie_sub_menu li a {
    line-height: 33px;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
  width: 620px;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
  width: 33.33%;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
    width: 100%;
  }
}

.categorie_sub {
  opacity: 0;
  z-index: 999;
  position: absolute;
  width: 101%;
  left: -1px;
  border: lef;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
  top: 65%;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub {
    width: 100%;
    left: 0px;
  }
}

.categorie_sub.open {
  top: 95%;
  opacity: 1;
  visibility: visible;
}

.categories_menu_toggle ul li.has-sub>a::before {
  position: absolute;
  content: "\f055";
  font-family: FontAwesome;
  top: 0;
  right: 20px;
  font-size: 18px;
  pointer-events: none;
}

.categories_menu_toggle ul li.has-sub>a.open::before {
  content: "\f056";
}

.categories_menu_toggle ul li.has-sub ul.categorie_sub li a {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 16px;
  padding-left: 30px;
  display: block;
  color: #212121;
}

.categories_menu_toggle ul li.has-sub ul.categorie_sub li a:hover {
  color: #bd874a;
}

.categories_menu_toggle ul li.has-sub {
  padding-bottom: 10px;
}

.categories_menu_toggle ul li ul.categories_mega_menu>li:first-child {
  padding-left: 0;
}

.categories_menu_toggle ul li ul.categories_mega_menu>li:last-child {
  padding-right: 0;
}

.categorie_banner {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .categorie_banner {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_banner {
    display: none;
  }
}

.categorie_banner a img:hover {
  opacity: 0.8;
}

/*-- 2.7 Categories menu css end --*/
/* 04. slider area css here */
.single_slider {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .single_slider {
    background-position: 11%;
  }
}

.single_slider .row {
  height: 750px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_slider .row {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_slider .row {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_slider .row {
    height: 480px;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider .row {
    height: 330px;
  }
}

.single_slider .slider_content.content_one {
  margin-left: 13%;
  width: 550px;
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_slider .slider_content.content_one {
    margin-left: 8%;
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_slider .slider_content.content_one {
    margin-left: 5%;
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_slider .slider_content.content_one {
    margin-left: 5%;
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider .slider_content.content_one {
    margin-left: inherit;
    width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider .slider_content.content_one img {
    width: 200px;
    margin: 0 auto;
  }
}

.single_slider .slider_content.content_two {
  margin-left: 15%;
  width: 550px;
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_slider .slider_content.content_two {
    margin-left: 8%;
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_slider .slider_content.content_two {
    margin-left: 5%;
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_slider .slider_content.content_two {
    margin-left: 5%;
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider .slider_content.content_two {
    margin-left: inherit;
    width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider .slider_content.content_two img {
    width: 200px;
    margin: 0 auto;
  }
}

.single_slider .slider_content.content_three {
  text-align: center;
}

.single_slider .slider_content.content_three img {
  width: 600px;
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_slider .slider_content.content_three img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_slider .slider_content.content_three img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_slider .slider_content.content_three img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider .slider_content.content_three img {
    width: 165px;
  }
}

.active .slider_content img {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_style .slider_content {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_style .slider_content {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_style .slider_content {
    text-align: center;
  }
}

.slider_style .slider_content p {
  font-size: 16px;
  color: #fff;
  margin-top: 22px;
}

@media only screen and (max-width: 767px) {
  .slider_style .slider_content p {
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.slider_style .slider_content a {
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 22px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
  display: inline-block;
}

.slider_style .slider_content a:hover {
  color: #000000;
  border-color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_style .slider_content a {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_style .slider_content a {
    margin-top: 5px;
    font-size: 15px;
  }
}

.slider_area {
  margin-bottom: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_area {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_area {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_area {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_area {
    margin-bottom: 60px;
  }
}

.slider_area.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.slider_area.owl-carousel .owl-nav div {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  font-size: 60px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  transition: .3s;
  color: #5f5f5f;
  left: 40px;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_area.owl-carousel .owl-nav div {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_area.owl-carousel .owl-nav div {
    left: 5px;
    display: none;
  }
}

.slider_area.owl-carousel .owl-nav div:hover {
  color: #fff;
}

.slider_area.owl-carousel .owl-nav div.owl-next {
  right: 40px;
  left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_area.owl-carousel .owl-nav div.owl-next {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_area.owl-carousel .owl-nav div.owl-next {
    right: 5px;
  }
}

.slider_area.owl-carousel .owl-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .slider_area.owl-carousel .owl-dots {
    bottom: 10px;
  }
}

.slider_area.owl-carousel .owl-dots .owl-dot {
  width: 30px;
  height: 3px;
  display: inline-block;
  background: #ddd;
  margin: 0 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slider_area.owl-carousel .owl-dots .owl-dot.active {
  background: #bd874a;
}

/*home three css here*/
.home_three_slider {
  margin-bottom: 30px;
}

.home_three_slider .row {
  height: 780px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_three_slider .row {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_three_slider .row {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_three_slider .row {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .home_three_slider .row {
    height: 300px;
  }
}

.home_three_slider .single_slider .slider_content {
  position: relative;
}

.home_three_slider .single_slider .slider_content p {
  position: absolute;
  bottom: 130px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_three_slider .single_slider .slider_content p {
    bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_three_slider .single_slider .slider_content p {
    bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_three_slider .single_slider .slider_content p {
    bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .home_three_slider .single_slider .slider_content p {
    bottom: 65px;
  }
}

.home_three_slider .single_slider .slider_content.content_one {
  margin-left: inherit;
  text-align: center;
  padding-left: 0;
  width: inherit;
}

@media only screen and (max-width: 767px) {
  .home_three_slider .single_slider .slider_content.content_one {
    padding-top: 50px;
  }
}

.home_three_slider .single_slider .slider_content.content_one img {
  width: 720px;
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_three_slider .single_slider .slider_content.content_one img {
    width: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_three_slider .single_slider .slider_content.content_one img {
    width: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_three_slider .single_slider .slider_content.content_one img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .home_three_slider .single_slider .slider_content.content_one img {
    width: 230px;
    position: relative;
    top: -50px;
  }
}

.home_three_slider .single_slider .slider_content.content_one a {
  margin-top: 0;
}

.home_three_slider .single_slider .slider_content.content_two {
  text-align: center;
  margin-left: inherit;
  width: inherit;
}

.home_three_slider .single_slider .slider_content.content_two img {
  width: 800px;
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_three_slider .single_slider .slider_content.content_two img {
    width: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_three_slider .single_slider .slider_content.content_two img {
    width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_three_slider .single_slider .slider_content.content_two img {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .home_three_slider .single_slider .slider_content.content_two img {
    width: 230px;
    position: relative;
    top: -50px;
  }
}

.home_three_slider .single_slider .slider_content.content_three {
  text-align: center;
}

.home_three_slider .single_slider .slider_content.content_three img {
  width: 700px;
  position: relative;
  top: -85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_three_slider .single_slider .slider_content.content_three img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_three_slider .single_slider .slider_content.content_three img {
    width: 480px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_three_slider .single_slider .slider_content.content_three img {
    width: 380px;
  }
}

@media only screen and (max-width: 767px) {
  .home_three_slider .single_slider .slider_content.content_three img {
    width: 230px;
    top: -52px;
  }
}

.home_three_slider .single_slider .slider_content.content_three p {
  bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .home_three_slider .single_slider .slider_content.content_three p {
    bottom: 45px;
  }
}

.home_three_slider .single_slider .slider_content.content_three a {
  margin-top: 0;
}

/*home four css here*/
.home_four_slider {
  margin-bottom: 98px;
}

.home_four_slider .row {
  height: 850px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_four_slider .row {
    height: 680px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_four_slider .row {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_four_slider .row {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .home_four_slider .row {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_four_slider {
    margin-bottom: 78px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_four_slider {
    margin-bottom: 78px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_four_slider {
    margin-bottom: 58px;
  }
}

@media only screen and (max-width: 767px) {
  .home_four_slider {
    margin-bottom: 59px;
  }
}

.home_four_slider .slider_content {
  text-align: center;
  margin-top: 380px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_four_slider .slider_content {
    margin-top: 310px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_four_slider .slider_content {
    margin-top: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_four_slider .slider_content {
    margin-top: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .home_four_slider .slider_content {
    margin-top: 0;
  }
}

.home_four_slider .slider_content.content_one {
  width: inherit;
  margin-left: 0;
}

.home_four_slider .slider_content.content_two {
  width: inherit;
  margin-left: 0;
}

.home_four_slider .slider_content h1 {
  font-size: 46px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 14px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_four_slider .slider_content h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .home_four_slider .slider_content h1 {
    font-size: 17px;
    margin-bottom: 8px;
    line-height: 25px;
  }
}

.home_four_slider .slider_content h2 {
  font-size: 40px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_four_slider .slider_content h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .home_four_slider .slider_content h2 {
    font-size: 16px;
    line-height: 25px;
  }
}

.home_four_slider .slider_content a {
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 38px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
  display: inline-block;
}

.home_four_slider .slider_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_four_slider .slider_content a {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .home_four_slider .slider_content a {
    margin-top: 10px;
    font-size: 15px;
  }
}

.active .slider_content h1,
.active .slider_content h2,
.active .slider_content a {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.active .slider_content h2 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.active .slider_content a {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

/*home six slider css here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_section.slider_section_six {
    margin-bottom: 51px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_section.slider_section_six {
    margin-bottom: 25px;
  }
}

.home_six_slider {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_six_slider {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .home_six_slider {
    margin-bottom: 60px;
  }
}

.home_six_slider .single_slider {
  height: 747px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_six_slider .single_slider {
    height: 511px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_six_slider .single_slider {
    height: 362px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_six_slider .single_slider {
    height: 380px;
  }
}

@media only screen and (max-width: 767px) {
  .home_six_slider .single_slider {
    height: 380px;
    background-position: 0%;
  }
}

.home_six_slider .slider_content {
  display: table-cell;
  vertical-align: middle;
  padding-left: 90px;
}

@media only screen and (max-width: 767px) {
  .home_six_slider .slider_content {
    padding-left: 0;
    text-align: center;
  }
}

.home_six_slider .slider_content h2 {
  font-size: 20px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 500;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .home_six_slider .slider_content h2 {
    font-size: 18px;
  }
}

.home_six_slider .slider_content h1 {
  text-transform: uppercase;
  color: #232323;
  font-weight: 600;
  font-size: 80px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_six_slider .slider_content h1 {
    font-size: 68px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_six_slider .slider_content h1 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_six_slider .slider_content h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .home_six_slider .slider_content h1 {
    font-size: 25px;
  }
}

.home_six_slider .slider_content p {
  color: #242424;
  font-size: 16px;
  font-weight: 500;
  padding-right: 120px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .home_six_slider .slider_content p {
    padding-right: 0;
    font-size: 14px;
  }
}

.home_six_slider .slider_content a {
  font-size: 16px;
  color: #242424;
  text-transform: capitalize;
  margin-top: 30px;
  padding-bottom: 5px;
  border-bottom: 2px solid #242424;
  font-weight: 600;
  display: inline-block;
}

.home_six_slider .slider_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

.home_six_slider .slider_three .slider_content {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .home_six_slider .slider_three .slider_content {
    text-align: center;
  }
}

.slider_content_inner {
  display: table;
  width: 100%;
  height: 100%;
}

.active .slider_content p {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}

.slider_section {
  margin-bottom: 92px;
  padding: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_section {
    padding: 0 30px;
    margin-bottom: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_section {
    padding: 0 30px;
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_section {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_section {
    padding: 0;
  }
}

/*slider seven css here*/
.home_seven_slider {
  margin-bottom: 93px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_seven_slider {
    margin-bottom: 71px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_seven_slider {
    margin-bottom: 71px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven_slider {
    margin-bottom: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .home_seven_slider {
    margin-bottom: 54px;
  }
}

.home_seven_slider.owl-carousel .owl-dots .owl-dot {
  background: #ffffff;
}

.home_seven_slider.owl-carousel .owl-dots .owl-dot.active {
  background: #000000;
}

.home_seven_slider .single_slider {
  height: 100vh;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven_slider .single_slider {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .home_seven_slider .single_slider {
    height: 300px;
    background-position: 0%;
  }
}

.home_seven_slider .slider_content {
  display: table-cell;
  vertical-align: middle;
  padding-left: 260px;
  text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_seven_slider .slider_content {
    padding-left: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_seven_slider .slider_content {
    padding-left: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven_slider .slider_content {
    padding-left: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .home_seven_slider .slider_content {
    padding-left: 0;
    text-align: center;
  }
}

.home_seven_slider .slider_content h2 {
  color: #242424;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .home_seven_slider .slider_content h2 {
    font-size: 18px;
  }
}

.home_seven_slider .slider_content h1 {
  color: #232323;
  font-size: 80px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_seven_slider .slider_content h1 {
    font-size: 68px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_seven_slider .slider_content h1 {
    font-size: 68px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven_slider .slider_content h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .home_seven_slider .slider_content h1 {
    font-size: 25px;
  }
}

.home_seven_slider .slider_content p {
  color: #242424;
  font-size: 17px;
  width: 529px;
  font-weight: 500;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .home_seven_slider .slider_content p {
    width: 100%;
    font-size: 14px;
  }
}

.home_seven_slider .slider_content a {
  font-size: 16px;
  color: #242424;
  text-transform: capitalize;
  margin-top: 20px;
  padding-bottom: 5px;
  font-weight: 600;
  border-bottom: 2px solid #242424;
  display: inline-block;
}

.home_seven_slider .slider_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .home_seven_slider .slider_content a {
    font-size: 14px;
    margin-top: 0;
  }
}

/* 06. banner section css here */
.banner_conteiner {
  /* background: url(../img/bg/banner3.jpg) no-repeat scroll center center/cover; */
  background: url(../src/assets/img/bg/banner3.jpg) no-repeat scroll center center/cover;
  height: 550px;
  display: table;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .banner_conteiner {
    height: 350px;
  }
}

.banner_section {
  padding: 0 120px 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_section {
    padding: 0 30px 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_section {
    padding: 0 30px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section {
    padding: 0 20px 53px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section {
    padding: 0 0 56px;
  }
}

.banner_text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 120px;
}

@media only screen and (max-width: 767px) {
  .banner_text {
    padding-left: 0;
    text-align: center;
  }
}

.banner_text h1 {
  font-size: 48px;
  font-weight: 600;
  color: #242424;
  text-transform: capitalize;
  margin-bottom: 0;
  line-height: 54px;
}

@media only screen and (max-width: 767px) {
  .banner_text h1 {
    font-size: 20px;
    line-height: 28px;
  }
}

.banner_text a {
  color: #242424;
  font-size: 16px;
  margin-top: 75px;
  display: inline-block;
  vertical-align: top;
  border-bottom: 2px solid #242424;
}

.banner_text a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .banner_text a {
    margin-top: 30px;
  }
}

.banner_thumb {
  position: relative;
}

.banner_thumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
}

.banner_thumb:hover::before {
  opacity: 1;
}

/*home two css here*/
.banner_section_two {
  padding: 0 0 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_two {
    padding: 0 20px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_two {
    padding: 0 0 34px;
  }
}

.banner_section_two .banner_thumb::before {
  background: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 767px) {
  .banner_section_two .banner_thumb {
    margin-bottom: 25px;
  }
}

.banner_section_two .banner_content {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: 20px;
  right: 20px;
  text-align: center;
}

.banner_section_two .banner_content h1 {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 12px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_two .banner_content h1 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_two .banner_content h1 {
    font-size: 35px;
  }
}

.banner_section_two .banner_content p {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .banner_section_two .banner_content p {
    font-size: 16px;
  }
}

.banner_section_two .banner_content p.desc {
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .banner_section_two .banner_content p.desc {
    margin-top: 10px;
  }
}

.banner_section_two .banner_content a {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  padding-bottom: 2px;
  border-bottom: 2px solid #fff;
  margin-top: 57px;
  display: inline-block;
}

.banner_section_two .banner_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_two .banner_content a {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_two .banner_content a {
    margin-top: 5px;
  }
}

.banner_section_two.bottom {
  padding-bottom: 93px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_section_two.bottom {
    padding-bottom: 73px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_section_two.bottom {
    padding-bottom: 73px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_two.bottom {
    padding: 0 20px 52px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_two.bottom {
    padding-bottom: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_two.bottom .banner_content p {
    font-size: 14px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_two.bottom .banner_content h1 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 5px;
  }
}

/*home three css here*/
.banner_section_three {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 91px;
}

@media only screen and (max-width: 767px) {
  .banner_section_three {
    padding-bottom: 25px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_three .banner_area {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_three .banner_area.bottom {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_section_three {
    padding-bottom: 71px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_section_three {
    padding-bottom: 73px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_three {
    padding-bottom: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_three .banner_thumb a {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_three .banner_thumb a img {
    width: 100%;
  }
}

.banner_section_three .banner_thumb::before {
  display: none;
}

.banner_section_three .banner_thumb::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 10px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  pointer-events: none;
}

.banner_section_three .banner_thumb:hover::after {
  opacity: 1;
}

.banner_section_three .banner_content {
  position: absolute;
  content: "";
  top: 50%;
  transform: translatey(-50%);
  left: 40px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_three .banner_content {
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_three .banner_content {
    left: 0;
    right: 0;
  }
}

.banner_section_three .banner_content h1 {
  font-size: 36px;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 31px;
  line-height: 44px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_three .banner_content h1 {
    font-size: 22px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_three .banner_content h1 {
    font-size: 17px;
    line-height: 25px;
  }
}

.banner_section_three .banner_content a {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
  margin-top: 50px;
}

.banner_section_three .banner_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_three .banner_content a {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_three .banner_content a {
    margin-top: 10px;
    display: inline;
  }
}

/*home six css here*/
.banner_slider_section .banner_content {
  position: absolute;
  top: 14%;
  left: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_slider_section .banner_content {
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_slider_section .banner_content {
    left: 10px;
  }
}

.banner_slider_section .banner_content h1 {
  color: #242424;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_slider_section .banner_content h1 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_slider_section .banner_content h1 {
    font-size: 15px;
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_slider_section .banner_content h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.banner_slider_section .banner_content h3 {
  font-size: 16px;
  color: #242424;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_slider_section .banner_content h3 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_slider_section .banner_content h3 {
    font-size: 14px;
    line-height: 24px;
  }
}

.banner_slider_section .banner_top {
  margin-bottom: 30px;
}

.banner_slider_section.section_fullwidth {
  margin-bottom: 91px;
  padding: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_slider_section.section_fullwidth {
    padding: 0 30px;
    margin-bottom: 71px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_slider_section.section_fullwidth {
    padding: 0 30px;
    margin-bottom: 71px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_slider_section.section_fullwidth {
    padding: 0 20px;
    margin-bottom: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_slider_section.section_fullwidth {
    padding: 0;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_slider_section .banner_thumb {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_slider_section .banner_thumb a {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_slider_section .banner_thumb a {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_slider_section .banner_thumb a img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_slider_section .banner_thumb a img {
    width: 100%;
  }
}

/*banner seven css here*/
.banner_seven {
  padding-bottom: 92px;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_seven {
    padding-bottom: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_seven {
    padding-bottom: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_seven {
    padding-bottom: 52px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_seven {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

.banner_seven .banner_content {
  left: 90px;
  right: inherit;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_seven .banner_content {
    left: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_seven .banner_content {
    left: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_seven .banner_content {
    left: 20px;
    right: 20px;
  }
}

.banner_seven .banner_content h1 {
  letter-spacing: -0.05em;
  font-size: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_seven .banner_content h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_seven .banner_content h1 {
    font-size: 21px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_seven .banner_content h1 {
    font-size: 18px;
    line-height: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_seven .banner_content a {
    margin-top: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_seven .banner_content a {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner_seven .banner_content a {
    font-size: 14px;
    line-height: 20px;
  }
}

/*banner eight css here*/
.banner_section_eight {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_eight {
    padding-bottom: 59px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_eight {
    padding-bottom: 29px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_eight .banner_area {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_eight .banner_area.bottom {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_eight .banner_thumb>a {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_eight .banner_thumb>a img {
    width: 100%;
  }
}

.banner_section_eight .banner_content {
  position: absolute;
  bottom: 70px;
  left: 25px;
}

.banner_section_eight .banner_content h1 {
  color: #242424;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_section_eight .banner_content h1 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_eight .banner_content h1 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_eight .banner_content h1 {
    font-size: 28px;
  }
}

.banner_section_eight .banner_content p {
  color: #242424;
  font-size: 15px;
  font-weight: 500;
}

.banner_section_eight .banner_content a {
  color: #242424;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 2px solid #242424;
  margin-top: 20px;
  display: inline-block;
}

.banner_section_eight .banner_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .banner_section_eight .banner_content a {
    font-size: 15px;
    margin-top: 8px;
  }
}

/* 05. product section css here */
.section_title {
  text-align: center;
  margin-bottom: 28px;
}

@media only screen and (max-width: 767px) {
  .section_title {
    margin-bottom: 15px;
  }
}

.section_title h2 {
  font-size: 36px;
  color: #242424;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 11px;
  line-height: 38px;
  letter-spacing: -0.05em;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .section_title h2 {
    font-size: 22px;
    line-height: 25px;
  }
}

.section_title p {
  margin-bottom: 0;
}

.tab-content>.tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}

.tab-content>.tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.product_tab_button {
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .product_tab_button {
    margin-bottom: 25px;
  }
}

.product_tab_button ul {
  justify-content: center;
}

.product_tab_button ul li {
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul li {
    padding-right: 17px;
  }
}

.product_tab_button ul li:last-child {
  padding-right: 0;
}

.product_tab_button ul li a {
  font-size: 13px;
  color: #747474;
  text-transform: capitalize;
}

.product_tab_button ul li a:hover {
  color: #bd874a;
}

.product_tab_button ul li a.active {
  color: #bd874a;
}

.product_section {
  padding: 0 120px 54px;

}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_section {
    padding: 0 30px 39px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_section {
    padding: 0 30px 39px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_section {
    padding: 0 20px 39px;
  }
}

@media only screen and (max-width: 767px) {
  .product_section {
    padding: 0 0 39px;
  }
}

.product_section--p0 {
  padding-left: 0;
  padding-right: 0;
}

.product_container:hover button {
  opacity: 1;
  visibility: visible;
  left: -20px;
}

@media only screen and (max-width: 767px) {
  .product_container:hover button {
    left: 0;
  }
}

.product_container:hover button.next_arrow {
  right: -20px;
}

@media only screen and (max-width: 767px) {
  .product_container:hover button.next_arrow {
    right: 0;
  }
}

.product_container .col-lg-3 {
  flex: 0 0 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 479px) {
  .product_container .col-lg-3 {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.product_container button {
  position: absolute;
  top: 47%;
  transform: translatey(-50%);
  border: 0;
  background: inherit;
  font-size: 50px;
  z-index: 9;
  left: 0;
  color: #a9a9a9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: inherit;
}

@media only screen and (max-width: 767px) {
  .product_container button {
    left: 15px;
  }
}

.product_container button:hover {
  color: #bd874a;
}

.product_container button.next_arrow {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product_container button.next_arrow {
    right: 15px;
  }
}

.single_product {
  margin-bottom: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_product {
    margin-bottom: 33px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_product {
    margin-bottom: 33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_product {
    margin-bottom: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .single_product {
    margin-bottom: 33px;
  }
}

.single_product:hover .product_thumb a.secondary_img {
  opacity: 1;
  visibility: visible;
}

.single_product:hover .product_action {
  opacity: 1;
  visibility: visible;
}

.single_product:hover .quick_button {
  opacity: 1;
  visibility: visible;
}

.product_thumb {
  position: relative;
  margin-bottom: 14px;
}

.product_thumb a.secondary_img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.product_action {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product_action:hover .action_button {
  opacity: 1;
  visibility: visible;
}

.action_button {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.action_button ul li a {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
  color: #292929;
  font-size: 15px;
  position: relative;
  display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .action_button ul li a {
    height: 41px;
    line-height: 41px;
  }
}

@media only screen and (max-width: 479px) {
  .action_button ul li a {
    width: 38px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }
}

.action_button ul li a:hover {
  color: #bd874a;
}

.action_button ul li a::before {
  position: absolute;
  content: "";
  background: #ddd;
  height: 1px;
  width: 25px;
  bottom: 0;
  left: 13px;
}

@media only screen and (max-width: 479px) {
  .action_button ul li a::before {
    left: 7px;
  }
}

.action_button ul li:last-child a::before {
  display: none;
}

.hover_action {
  position: relative;
}

.hover_action a {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
  color: #909090;
  font-size: 15px;
  color: #999;
  display: block;
}

.hover_action a:hover {
  color: #bd874a;
}

@media only screen and (max-width: 479px) {
  .hover_action a {
    width: 38px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }
}

.quick_button {
  position: absolute;
  bottom: 15px;
  left: 10px;
  right: 10px;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (max-width: 767px) {
  .quick_button {
    text-align: center;
  }
}

@media only screen and (max-width: 479px) {
  .quick_button {
    bottom: 8px;
    left: 20px;
    right: 20px;
  }
}

.quick_button a {
  line-height: 45px;
  background: #fff;
  color: #999;
  padding: 0 10px;
  font-weight: normal;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  display: block;
}

.quick_button a:hover {
  color: #bd874a;
}

@media only screen and (max-width: 479px) {
  .quick_button a {
    line-height: 30px;
    padding: 0 8px;
    font-size: 12px;
  }
}

.product_sale {
  position: absolute;
  top: 15px;
  left: 15px;
  background: #bd874a;
  padding: 0 13px;
  border-radius: 2px;
}

.product_sale span {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  line-height: 23px;
}

.double_base .product_sale {
  top: 46px;
  padding: 0 15px;
}

.product_content h3 {
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_content h3 {
    font-size: 12px;
  }
}

.product_content h3 a {
  color: #999;
}

.product_content h3 a:hover {
  color: #bd874a;
}

.product_content span {
  font-size: 13px;
  font-weight: 500;
  color: #242424;
}

.product_content span.old_price {
  color: #a4a4a4;
  text-decoration: line-through;
  margin-left: 5px;
}

.label_product {
  position: absolute;
  top: 15px;
  left: 15px;
  background: #3a3a3a;
  padding: 0 13px;
  border-radius: 2px;
}

.label_product span {
  text-transform: capitalize;
  color: #fff;
}

.mens_product {
  padding: 0 120px 47px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .mens_product {
    padding: 0 30px 33px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mens_product {
    padding: 0 30px 33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mens_product {
    padding: 0 30px 52px;
  }
}

@media only screen and (max-width: 767px) {
  .mens_product {
    padding: 0 0 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .womens_product {
    padding-bottom: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .womens_product {
    padding-bottom: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .womens_product .banner_area {
    margin-bottom: 51px;
  }
}

@media only screen and (max-width: 767px) {
  .womens_product .banner_area {
    margin-bottom: 54px;
  }

  .categoryarea {
    padding: 0;
  }

  .ec_ser_inner {
    padding: 15px;
  }

  .footer_top .row {
    gap: 1rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mens_product .banner_area {
    margin-top: 21px;
  }
}

@media only screen and (max-width: 767px) {
  .mens_product .banner_area {
    margin-top: 21px;
  }
}

/*home three css here*/
.product_carousel .col-lg-3 {
  flex: 0 0 100%;
  width: 100%;
}

.product_carousel .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  border: 0;
  background: inherit;
  font-size: 50px;
  z-index: 9;
  left: 0;
  color: #a9a9a9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 767px) {
  .product_carousel .owl-nav div {
    left: 10px;
  }
}

.product_carousel .owl-nav div:hover {
  color: #bd874a;
}

.product_carousel .owl-nav div.owl-next {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product_carousel .owl-nav div.owl-next {
    right: 10px;
  }
}

.product_carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
  left: -20px;
}

@media only screen and (max-width: 767px) {
  .product_carousel:hover .owl-nav div {
    left: 0;
  }
}

.product_carousel:hover .owl-nav div.owl-next {
  right: -20px;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product_carousel:hover .owl-nav div.owl-next {
    right: 0;
  }
}

.product_section.womens_product.bottom {
  padding-bottom: 46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_section.womens_product.bottom {
    padding-bottom: 31px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_section.womens_product.bottom {
    padding-bottom: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_section.womens_product.bottom {
    padding-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .product_section.womens_product.bottom {
    padding-bottom: 16px;
  }
}

/*home four css here*/
.product_section_four {
  padding-bottom: 95px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_section_four {
    padding-bottom: 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_section_four {
    padding-bottom: 74px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_section_four {
    padding-bottom: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .product_section_four {
    padding-bottom: 56px;
  }
}

.product_section_four .product_area {
  border-bottom: 1px solid #ddd;
  padding-bottom: 54px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_section_four .product_area {
    padding-bottom: 41px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_section_four .product_area {
    padding-bottom: 41px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_section_four .product_area {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product_section_four .product_area {
    padding-bottom: 20px;
  }
}

/*home six css here*/
.product_section_six {
  padding-bottom: 53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_section_six {
    padding-bottom: 38px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_section_six {
    padding-bottom: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_section_six {
    padding-bottom: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .product_section_six {
    padding-bottom: 19px;
  }
}

.product_section_six.bottom {
  padding-bottom: 31px;
}

@media only screen and (max-width: 767px) {
  .product_section_six.bottom {
    padding-bottom: 15px;
  }
}

/*home eight css here*/
.product_section_eight {
  padding-bottom: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .product_section_eight {
    padding-bottom: 32px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_section_eight {
    padding-bottom: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_section_eight {
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product_section_eight {
    padding-bottom: 16px;
  }
}

/* 13. widget area css here */
.product_categorie {
  margin-bottom: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_categorie {
    margin-bottom: 39px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_categorie {
    margin-bottom: 39px;
  }
}

@media only screen and (max-width: 767px) {
  .product_categorie {
    margin-bottom: 39px;
  }
}

.categorie_widget {
  border: 1px solid #ddd;
  margin-top: 30px;
  padding: 17px 30px 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_widget {
    margin-top: 0;
    margin-bottom: 44px;
  }
}

@media only screen and (max-width: 767px) {
  .categorie_widget {
    margin-top: 0;
    margin-bottom: 42px;
  }
}

.categorie_widget ul {
  padding-left: 13px;
}

.categorie_widget ul li {
  line-height: 20px;
  position: relative;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categorie_widget ul li {
    margin-bottom: 6px;
  }
}

.categorie_widget ul li:last-child {
  margin-bottom: 0;
}

.categorie_widget ul li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #747474;
  border-radius: 50%;
  top: 50%;
  transform: translatey(-50%);
  left: -13px;
}

.categorie_widget ul li a {
  color: #747474;
  font-size: 13px;
}

.categorie_widget ul li a:hover {
  color: #f6f6f6;
}

/* home two widget area css here*/
.product_c_two {
  margin-bottom: 39px;
}

.product_c_two .section_title {
  margin-bottom: 0;
  border-bottom: 0;
}

.product_c_two .categorie_widget {
  padding: 20px 30px 20px;
  margin-top: 0;
}

.product_c_two .categorie_widget ul li {
  display: inline-block;
  width: 15%;
  margin-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_c_two .categorie_widget ul li {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product_c_two .categorie_widget ul li {
    display: block;
    width: 100%;
  }
}

/* 07. blog area css here */
.blog_section {
  padding-bottom: 91px;
}

.blog_section .section_title {
  margin-bottom: 49px;
}

@media only screen and (max-width: 767px) {
  .blog_section .section_title {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_section {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 71px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_section {
    padding-bottom: 71px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_section {
    padding-bottom: 51px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_section {
    padding-bottom: 54px;
  }
}

.blog_wrapper {
  padding-bottom: 95px;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_wrapper {
    padding-bottom: 75px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_wrapper {
    padding-bottom: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper {
    padding-bottom: 54px;
  }
}

.blog_wrapper .col-lg-4 {
  flex: 0 0 100%;
  width: 100%;
}

.blog_thumb iframe {
  width: 100%;
}

.blog_thumb {
  position: relative;
  margin-bottom: 22px;
}

.blog_thumb>a {
  display: block;
}

@media only screen and (max-width: 767px) {
  .blog_thumb>a {
    width: 100%;
  }
}

.blog_thumb:hover::before {
  opacity: 1;
  visibility: visible;
}

.blog_thumb:hover .blog_icon {
  opacity: 1;
  visibility: visible;
}

.blog_thumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.blog_icon {
  position: absolute;
  position: absolute;
  top: 48%;
  transform: translatey(-50%);
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.blog_icon a {
  width: 52px;
  height: 52px;
  background: #bd874a;
  position: relative;
  display: inline-block;
}

.blog_icon a::before {
  position: absolute;
  content: "\e032";
  font-family: 'simple-line-icons';
  font-size: 23px;
  line-height: 52px;
  color: #fff;
  width: 100%;
  text-align: center;
  left: 0;
}

.blog_icon a::after {
  position: absolute;
  content: "";
  width: 54px;
  height: 54px;
  border: 1px solid #bd874a;
  left: -6px;
  top: 4px;
}

.blog_content h3 {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 600;
  margin-bottom: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_content h3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_content h3 {
    font-size: 14px;
  }
}

.blog_content h3 a {
  color: #242424;
}

.blog_content h3 a:hover {
  color: #bd874a;
}

.author_name {
  margin-bottom: 11px;
}

.author_name p {
  font-size: 13px;
  text-transform: capitalize;
  color: #747474;
}

.author_name p span.themes {
  color: #bd874a;
}

.author_name p span.post_by {
  color: #242424;
}

.post_desc p {
  line-height: 22px;
}

.blog_wrapper:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
  left: -25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper:hover .owl-nav div {
    left: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper:hover .owl-nav div {
    left: 0px;
  }
}

.blog_wrapper:hover .owl-nav div.owl-next {
  right: -25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper:hover .owl-nav div.owl-next {
    right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper:hover .owl-nav div.owl-next {
    right: 0px;
  }
}

.blog_wrapper .owl-nav>div {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  border: 0;
  background: inherit;
  font-size: 50px;
  z-index: 9;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #a9a9a9;
}

.blog_wrapper .owl-nav>div:hover {
  color: #bd874a;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_wrapper .owl-nav>div {
    left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_wrapper .owl-nav>div {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper .owl-nav>div {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .owl-nav>div {
    left: 15px;
  }
}

.blog_wrapper .owl-nav>div.owl-next {
  right: 0px;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_wrapper .owl-nav>div.owl-next {
    right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_wrapper .owl-nav>div.owl-next {
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_wrapper .owl-nav>div.owl-next {
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .owl-nav>div.owl-next {
    right: 15px;
  }
}

/*home three blog css here*/
.blog_section_three {
  padding-bottom: 96px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_section_three {
    padding-bottom: 76px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_section_three {
    padding-bottom: 76px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_section_three {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_section_three {
    padding-bottom: 55px;
  }
}

/* 08. instagram area css here */
.instagram_wrapper .col-lg-3 {
  flex: 0 0 100%;
  width: 100%;
}

.instagram_wrapper .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  border: 0;
  background: inherit;
  font-size: 50px;
  z-index: 9;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #a9a9a9;
}

@media only screen and (max-width: 767px) {
  .instagram_wrapper .owl-nav div {
    left: 15px;
  }
}

.instagram_wrapper .owl-nav div.owl-next {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .instagram_wrapper .owl-nav div.owl-next {
    right: 15px;
  }
}

.instagram_wrapper .owl-nav div:hover {
  color: #bd874a;
}

.instagram_wrapper:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
  left: -10px;
}

@media only screen and (max-width: 767px) {
  .instagram_wrapper:hover .owl-nav div {
    left: 0;
  }
}

.instagram_wrapper:hover .owl-nav div.owl-next {
  right: -10px;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .instagram_wrapper:hover .owl-nav div.owl-next {
    right: 0;
  }
}

.instagram_area {
  padding: 0 120px 94px;
}

.instagram_area .section_title {
  margin-bottom: 49px;
}

@media only screen and (max-width: 767px) {
  .instagram_area .section_title {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .instagram_area {
    padding: 0 30px 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram_area {
    padding: 0 30px 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram_area {
    padding: 0 20px 55px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram_area {
    padding: 0 0 55px;
  }
}

.single_instagram {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single_instagram a {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single_instagram a img {
    width: 100%;
  }
}

.single_instagram::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(36, 36, 36, 0.8);
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single_instagram:hover::before {
  opacity: 1;
}

.single_instagram:hover .instagram_icone {
  opacity: 1;
}

.owl-carousel .owl-item .single_instagram a img {
  display: inline-block;
}

.instagram_icone {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.instagram_icone a {
  font-size: 24px;
  color: #fff;
}

.instagram_home_block {
  padding-bottom: 94px;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .instagram_home_block {
    padding-bottom: 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram_home_block {
    padding-bottom: 74px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram_home_block {
    padding-bottom: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram_home_block {
    padding-bottom: 53px;
  }
}

.text_follow {
  text-align: center;
  margin-top: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text_follow {
    margin-top: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .text_follow {
    margin-top: 13px;
  }
}

.text_follow a {
  color: #999;
  font-size: 13px;
  line-height: 18px;
}

.text_follow a:hover {
  color: #bd874a;
}

.instagram_seven {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram_seven {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram_seven {
    padding-left: 0;
    padding-right: 0;
  }
}

.instagram_eight {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .instagram_eight {
    padding-left: 0;
    padding-right: 0;
  }
}

/* 23. shipping css here */
.shipping_carousel {
  padding-bottom: 51px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 92px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .shipping_carousel {
    margin-bottom: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shipping_carousel {
    padding-bottom: 51px;
    margin-bottom: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shipping_carousel {
    padding-bottom: 55px;
    margin-bottom: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .shipping_carousel {
    padding-bottom: 55px;
    margin-bottom: 54px;
  }
}

.shipping_carousel .col-lg-2 {
  flex: 0 0 100%;
  width: 100%;
}

.shipping_carousel .shipping_thumb a {
  display: block;
}

.shipping_carousel .shipping_thumb a img {
  width: inherit;
  margin: 0 auto;
}

.shipping_content a {
  color: #b5b5b5;
  font-size: 14px;
  display: block;
  font-weight: 600;
  line-height: 22px;
  text-transform: capitalize;
  padding-top: 15px;
}

.shipping_content a:hover {
  color: #242424;
}

.single_shipping {
  text-align: center;
}

.shipping_nine .shipping_carousel {
  padding-top: 25px;
}

/* 09. footer area css here */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .footer_widgets {
    padding: 0 30px;
  }
}

.footer_top {
  border-bottom: 1px solid #ddd;
  padding-bottom: 92px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .footer_top {
    padding-bottom: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_top {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_top {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_top {
    padding-bottom: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widgets_container {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container.contact_us {
    margin-bottom: 34px;
  }
}

.widgets_container h3 {
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  margin-bottom: 16px;
  font-weight: 600;
  color: #242424;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widgets_container h3 {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container h3 {
    margin-bottom: 8px;
  }
}

.footer_menu ul li a {
  display: block;
  font-weight: 400;
  line-height: 30px;
  color: #747474;
}

.footer_menu ul li a:hover {
  color: #bd874a;
}

.footer_contact p {
  margin-bottom: 0;
  line-height: 30px;
}

.footer_contact p a:hover {
  color: #bd874a;
}

.footer_contact ul {
  margin-top: 10px;
}

.footer_contact ul li {
  display: inline-block;
  margin-right: 7px;
}

.footer_contact ul li:last-child {
  margin-right: 0;
}

.footer_contact ul li a {
  color: #b2b2b2;
  font-size: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #e1e1e1;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  display: block;
}

.footer_contact ul li a:hover {
  background: #242424;
  color: #fff;
  border-color: #242424;
}

.newleter-content p {
  line-height: 28px;
  margin-bottom: 10px;
}

.newleter-content form input {
  width: 100%;
  padding: 0 20px;
  border: 1px solid #e1e1e1;
  height: 45px;
  color: #cacaca;
  margin-bottom: 10px;
}

.newleter-content form button {
  background: #242424;
  line-height: 45px;
  padding: 0 10px;
  margin-top: 7px;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  border: 0;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.newleter-content form button:hover {
  background: #bd874a;
}

.mailchimp-error {
  text-align: center;
  color: green;
}

.mailchimp-success {
  text-align: center;
  color: green;
}

.mailchimp-success.active {
  margin-top: 20px;
}

.footer_custom_links {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer_custom_links {
    text-align: center;
  }
}

.footer_custom_links ul li {
  display: inline-block;
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .footer_custom_links ul li {
    padding-right: 20px;
  }
}

.footer_custom_links ul li:last-child {
  margin-right: 0;
}

.footer_custom_links ul li a {
  color: #747474;
  text-transform: capitalize;
}

.footer_custom_links ul li a:hover {
  color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .copyright_area {
    text-align: center;
  }
}

.copyright_area p {
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .copyright_area p {
    margin-bottom: 5px;
  }
}

.copyright_area p a {
  color: #bd874a;
}

.copyright_area p a:hover {
  text-decoration: underline;
}

.footer_bottom {
  padding: 30px 0;
}

@media only screen and (max-width: 767px) {
  .footer_bottom {
    padding: 20px 0;
  }
}

/*home five css here*/
.footer_widgets.fotter_five {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.footer_widgets.fotter_five .footer_top {
  padding-top: 75px;
}

@media only screen and (max-width: 767px) {
  .footer_widgets.fotter_five .footer_top {
    padding-top: 54px;
  }
}

.footer_show_button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.footer_show_button>a {
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  text-align: center;
  background: #242424;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
}

.footer_show_button>a:hover {
  background: #bd874a;
}

.footer_show_button>a:not([href]):not([tabindex]) {
  color: #fff;
}

.footer_show_button>a i {
  margin-left: 5px;
}

.footer_widgets_inner {
  max-height: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-top: 1px solid #ddd;
}

.footer_widgets_inner .widgets_container {
  text-align: left;
}

.footer_widgets_inner.open {
  max-height: 500px;
  background: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_widgets_inner.open {
    max-height: 350px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  .footer_widgets_inner.open {
    max-height: 300px;
    overflow: auto;
  }
}

/*home six css here*/
.footer_six {
  padding: 0 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .footer_six {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_six {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_six {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_six {
    padding: 0 0;
  }
}

/*collection area css here*/
.collection_area {
  margin-bottom: 94px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .collection_area {
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection_area {
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_area {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_area {
    margin-bottom: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_img {
    margin-bottom: 21px;
  }
}

.collection_img img:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 767px) {
  .collection_content {
    text-align: center;
  }
}

.collection_content h1 {
  font-size: 120px;
  color: #f1f1f1;
  font-weight: 700;
  line-height: 100px;
  margin-bottom: 20px;
  font-family: "Abril Fatface", cursive;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content h1 {
    font-size: 90px;
    line-height: 80px;
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content h1 {
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 11px;
  }
}

.collection_content h4 {
  font-size: 18px;
  color: #747474;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content h4 {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content h4 {
    margin-bottom: 5px;
    font-size: 16px;
  }
}

.collection_content h2 {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 20px;
  color: #242424;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: -0.05em;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content h2 {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content h2 {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 28px;
  }
}

.collection_content p {
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .collection_content p {
    font-size: 14px;
  }
}

.collection_content a {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 2px solid #242424;
  display: inline-block;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content a {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content a {
    margin-top: 15px;
    font-size: 15px;
  }
}

.collection_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .collection_2 {
    margin-bottom: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_2 .collection_content {
    margin-bottom: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_4 {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_4 .collection_content {
    margin-bottom: 29px;
  }
}

/*categories section css here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_area {
    margin-top: 68px;
  }
}

.categories_thumb {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .categories_thumb {
    height: 100vh;
  }
}

@media only screen and (max-width: 767px) {
  .categories_thumb a {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .categories_thumb img {
    height: 100%;
    object-fit: cover;
  }
}

.categories_content {
  position: absolute;
  top: 50%;
  right: 217px;
  transform: translatey(-50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .categories_content {
    right: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_content {
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content {
    right: 35px;
  }
}

.categories_content h4 {
  color: #242424;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.categories_content h2 {
  color: #242424;
  font-size: 70px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .categories_content h2 {
    font-size: 50px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_content h2 {
    font-size: 40px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content h2 {
    font-size: 30px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content h2 {
    font-size: 22px;
    margin-bottom: 10px;
    line-height: 22px;
  }
}

.categories_content p {
  color: #242424;
  font-size: 17px;
  width: 440px;
  font-weight: 500;
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content p {
    width: 310px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content p {
    font-size: 14px;
    width: 210px;
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 0;
  }
}

.categories_content a {
  text-transform: capitalize;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 2px solid #242424;
  margin-top: 35px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .categories_content a {
    margin-top: 15px;
  }
}

.categories_content a:hover {
  color: #bd874a;
  border-color: #bd874a;
}

#fp-nav {
  right: 31px;
  margin-top: -43.5px;
}

.categories_area .col-12 {
  padding: 0;
}

.categories_content.content_left {
  left: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .categories_content.content_left {
    left: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content.content_left {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content.content_left {
    left: 30px;
  }
}

/* 10. shop page css here */
.header_shop {
  border-bottom: 1px solid #ddd;
}

.header_shop .header_top {
  padding: 7px 0;
}

.header_shop .header_middel {
  padding: 40px 0 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_shop .header_middel {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_shop .header_middel {
    padding: 0;
  }
}

.header_shop .header_bottom {
  padding: 0;
}

.shop_inner_area {
  padding-bottom: 94px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 94px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .shop_inner_area {
    padding-bottom: 74px;
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop_inner_area {
    padding-bottom: 74px;
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_inner_area {
    padding-bottom: 54px;
    margin-bottom: 57px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_inner_area {
    padding-bottom: 54px;
    margin-bottom: 57px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_inner_area {
    padding-bottom: 54px;
    margin-bottom: 57px;
  }
}

.breadcrumbs_area {
  padding: 40px 0;
}

.breadcrumb_content ul {
  line-height: 15px;
}

.breadcrumb_content ul li {
  display: inline-block;
  text-transform: capitalize;
  font-size: 14px;
  margin-right: 5px;
  color: #242424;
}

.breadcrumb_content ul li:last-child {
  margin-right: 0;
}

.breadcrumb_content ul li a {
  color: #999;
}

.breadcrumb_content ul li a:hover {
  color: #bd874a;
}

.widget_list {
  margin-bottom: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget_list {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .widget_list {
    margin-bottom: 55px;
  }
}

.widget_list:last-child {
  margin-bottom: 0;
}

.widget_list h2 {
  color: #242424;
  font-size: 18px;
  margin: 0 0 15px;
  padding: 0 0 5px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 24px;
}

.widget_list>ul>li {
  padding-bottom: 8px;
}

.widget_list>ul>li:last-child {
  padding-bottom: 0;
}

.widget_list>ul>li>a {
  color: #242424;
  display: block;
  line-height: 27px;
}

.widget_list>ul>li>a:hover {
  color: #bd874a;
}

.widget_list>ul>li>a:hover span {
  background: #bd874a;
  color: #fff;
}

.widget_list>ul>li>a span {
  float: right;
  background: #ebebeb;
  color: #c3c3c3;
  font-size: 12px;
  width: 29px;
  height: 29px;
  line-height: 29px;
  text-align: center;
  border-radius: 50%;
}

.ui-slider-horizontal .ui-slider-range {
  background: #bd874a;
  height: 5px;
}

.ui-slider-horizontal {
  height: 3px;
  background: #dbdbdb;
  border: none;
  width: 92%;
  margin: 0 auto;
  margin-bottom: 22px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #fff;
  border: 0;
  border-radius: 0;
  width: 13px;
  height: 13px;
  top: -5px;
  cursor: e-resize;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.widget_list.widget_filter form {
  padding-top: 10px;
}

.widget_list.widget_filter form input {
  background: none;
  border: none;
  font-size: 12px;
  float: right;
  text-align: right;
  line-height: 31px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget_list.widget_filter form input {
    width: 65px;
  }
}

.widget_list.widget_filter form button {
  height: 30px;
  line-height: 30px;
  padding: 0 16px;
  text-transform: uppercase;
  color: #ffffff;
  background: #242424;
  border: 0;
  border-radius: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.widget_list.widget_filter form button:hover {
  background: #bd874a;
}

.tag_widget ul li {
  display: inline-block;
}

.tag_widget ul li a {
  margin: 0 6px 5px 0;
  display: block;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #ddd;
  background: #ffffff;
  padding: 0 15px;
  line-height: 29px;
  color: #747474;
  border-radius: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tag_widget ul li a {
    padding: 0 13px;
  }
}

.tag_widget ul li a:hover {
  background: #bd874a;
  color: #fff;
  border-color: #bd874a;
}

.shop_toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  padding: 8px 10px;
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .shop_toolbar {
    flex-direction: column-reverse;
    padding: 10px 10px 18px;
  }
}

.select_option {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .select_option {
    flex-direction: column;
  }
}

.select_option .nice-select {
  border: 0;
  height: 30px;
  line-height: 29px;
}

.select_option .nice-select ul.list {
  top: 114%;
  right: 0;
  width: 200px;
  max-height: 200px;
  overflow: auto;
}

.shop_title {
  margin-bottom: 20px;
}

.shop_title h1 {
  color: #242424;
  font-size: 33px;
  text-transform: capitalize;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .niceselect_option {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .page_amount {
    margin-bottom: 12px;
  }
}

.page_amount p {
  color: #747474;
}

@media only screen and (max-width: 767px) {
  .page_amount p {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
  }
}

.list_button ul li {
  margin-right: 12px;
}

.list_button ul li:last-child {
  margin-right: 0;
}

.list_button ul li a:hover {
  color: #bd874a;
}

.list_button ul li a i {
  margin-right: 5px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

.list_button ul li a.active {
  color: #bd874a;
}

.list_button ul li a.active i {
  background: #bd874a;
  color: #ffffff;
}

.product_ratting ul li {
  display: inline-block;
}

.product_ratting ul li a {
  color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .product_list_item .product_thumb {
    margin-bottom: 18px;
  }
}

.product_list_item .product_content h3 {
  margin-bottom: 10px;
}

.product_list_item .product_ratting {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .pagination {
    margin-top: 19px;
  }
}

.pagination ul li {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f1f1f1;
  color: #747474;
  border-radius: 3px;
  margin-left: 3px;
}

.pagination ul li:first-child {
  margin-left: 0;
}

.pagination ul li a {
  display: block;
  border-radius: 3px;
}

.pagination ul li a:hover {
  background: #bd874a;
  color: #ffffff;
}

.pagination ul li.current {
  background: #bd874a;
  color: #ffffff;
}

.pagination ul li.next {
  width: 40px;
}

.shop_toolbar.t_bottom {
  justify-content: center;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_toolbar.t_bottom {
    margin-bottom: 56px;
    margin-top: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar.t_bottom {
    padding: 15px 10px 15px;
    margin-top: 20px;
    margin-bottom: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar.t_bottom .pagination {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_reverse .shop_inner_area>.row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .shop_reverse .shop_inner_area>.row {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_reverse .tab-content .row {
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .shop_reverse .tab-content .row {
    flex-direction: row;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid_view .quick_button {
    bottom: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid_view .quick_button a {
    line-height: 37px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid_view .action_button ul li a {
    width: 43px;
    height: 40px;
    line-height: 38px;
  }
}

.grid_view .hover_action a {
  width: 43px;
  height: 40px;
  line-height: 38px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_area .list_view.active {
    margin-bottom: 54px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_area .list_view.active {
    margin-bottom: 54px;
  }
}

.shop_fullwidth .shop_inner_area {
  padding-bottom: 100px;
  margin-bottom: 96px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .shop_fullwidth .shop_inner_area {
    padding-bottom: 80px;
    margin-bottom: 76px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop_fullwidth .shop_inner_area {
    padding-bottom: 80px;
    margin-bottom: 76px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_fullwidth .shop_toolbar.t_bottom {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_fullwidth .shop_toolbar.t_bottom {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop_fullwidth .shop_inner_area {
    padding-bottom: 60px;
    margin-bottom: 57px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_fullwidth .shop_inner_area {
    padding-bottom: 60px;
    margin-bottom: 57px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_fullwidth .list_view.active {
    margin-bottom: 33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_fullwidth .list_view.active {
    margin-bottom: 33px;
  }
}

/* shop page css here*/
.shop_toolbar_btn>button {
  margin-right: 10px;
  border: 0;
  background: inherit;
}

.shop_toolbar_btn>button.btn-grid-3 {
  /* background: url(../img/icon/bkg_grid.png) no-repeat scroll center center; */
  background: url(../src/assets/img/icon/bkg_grid.png) no-repeat scroll center center;
  width: 20px;
  height: 20px;
}

.shop_toolbar_btn>button.btn-grid-3.active {
  background: url(../src/assets/img/icon/bkg_grid_hover.png) no-repeat scroll center center !important;
}

.shop_toolbar_btn>button.btn-grid-4 {
  background: url(../src/assets/img/icon/bkg_grid4.png) no-repeat scroll center center;
  width: 26px;
  height: 22px;
}

.shop_toolbar_btn>button.btn-grid-4.active {
  background: url(../src/assets/img/icon/bkg_grid4_hover.png) no-repeat scroll center center !important;
}

.shop_toolbar_btn>button.btn-grid-5 {
  background: url(../src/assets/img/icon/bkg_grid5.png) no-repeat scroll center center;
  width: 28px;
  height: 20px;
}

.shop_toolbar_btn>button.btn-grid-5.active {
  background: url(../src/assets/img/icon/bkg_grid5_hover.png) no-repeat scroll center center !important;
}

.shop_toolbar_btn>button.btn-list {
  background: url(../src/assets/img/icon/bkg_list.png) no-repeat scroll center center;
  width: 20px;
  height: 20px;
}

.shop_toolbar_btn>button.btn-list.active {
  background: url(../src/assets/img/icon/bkg_list_hover.png) no-repeat scroll center center !important;
}

.product_content.list_content {
  display: none;
}

.grid_list .product_content.grid_content {
  display: none;
}

.grid_list .product_content.list_content {
  display: block;
  min-width: 0 0 66.666667%;
  margin-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid_list .product_content.list_content {
    flex: 0 0 55.333%;
    min-width: 55.333%;
  }
}

@media only screen and (max-width: 767px) {
  .grid_list .product_content.list_content {
    flex: 0 0 100%;
    min-width: 100%;
    margin-left: 0;
  }
}

.grid_list .single_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .grid_list .single_product {
    flex-direction: column;
  }
}

.grid_list .product_thumb {
  margin-bottom: 0;
  min-width: 33.333333%;
  margin-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid_list .product_thumb {
    flex: 0 0 40.667%;
    min-width: 40.667%;
  }
}

@media only screen and (max-width: 767px) {
  .grid_list .product_thumb {
    flex: 0 0 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.col-cust-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: max-width;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-cust-5 {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
  }
}

@media only screen and (max-width: 767px) {
  .col-cust-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .col-cust-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shop_wrapper>div {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.shop_toolbar_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 8px 10px;
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .shop_toolbar_wrapper {
    flex-direction: column;
    padding: 15px 10px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar_btn {
    margin-bottom: 20px;
  }
}

/*.shop_list{
    & .single_product{
        display: flex;
    }
}*/
/* shop page css end*/
/* 15. product details css here */
.product_details {
  margin-bottom: 53px;
}

@media only screen and (max-width: 767px) {
  .product_details {
    margin-bottom: 42px;
  }
}

.header_product {
  border-bottom: 1px solid #ddd;
}

.product_d_right h1 {
  color: #747474;
  text-transform: capitalize;
  line-height: 20px;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .product_d_right h1 {
    font-size: 20px;
  }
}

.product_d_right .product_ratting {
  margin-bottom: 14px;
}

.product_d_right .product_price {
  margin-bottom: 11px;
}

.product_d_right .product_price span.current_price {
  color: #242424;
  font-size: 13px;
  font-weight: 500;
}

.product_d_right .product_desc {
  margin-bottom: 19px;
}

.product_d_right .product_desc p {
  width: 626px;
  font-size: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_d_right .product_desc p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_d_right .product_desc p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product_d_right .product_desc p {
    width: 100%;
  }
}

.product_d_right .priduct_social {
  display: inline-block;
  overflow: hidden;
}

.product_d_right .priduct_social h3 {
  color: #242424;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 15px;
  float: left;
  margin-bottom: 0;
}

.product_d_right .priduct_social ul {
  float: right;
  line-height: 31px;
  margin-left: 20px;
}

.product_d_right .priduct_social ul li {
  display: inline-block;
}

.product_d_right .priduct_social ul li a {
  color: fonts_color;
  margin-right: 8px;
}

.product_d_right .priduct_social ul li a:hover {
  color: #bd874a;
}

.product_d_right .priduct_social ul li:last-child {
  margin-right: 0;
}

.product_ratting ul li.review a {
  color: #747474;
  padding-left: 10px;
}

.product_variant.color,
.product_variant.size {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product_variant.color h3,
.product_variant.size h3 {
  color: #242424;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 15px;
  margin-bottom: 0;
  margin-right: 80px;
}

.product_variant.color .nice-select,
.product_variant.size .nice-select {
  height: 35px;
  line-height: 32px;
  margin-bottom: 0;
}

.product_variant.color .nice-select ul.list,
.product_variant.size .nice-select ul.list {
  max-height: 150px;
  overflow: auto;
}

.product_variant.quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity {
    margin-bottom: 16px;
  }
}

.product_variant.quantity label {
  color: #242424;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 15px;
  margin-bottom: 0;
}

.product_variant.quantity input {
  width: 94px;
  border: 1px solid #ddd;
  background: none;
  height: 50px;
  padding: 0 5px;
  border-radius: 2px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity input {
    height: 45px;
    width: 60px;
  }
}

.product_variant.quantity button {
  border: 0;
  margin-left: 15px;
  background: #242424;
  font-size: 13px;
  line-height: 50px;
  padding: 0 100px;
  height: 50px;
  border-radius: 3px;
}

.product_variant.quantity button:hover {
  background: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_variant.quantity button {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity button {
    padding: 0 27px;
    font-size: 12px;
    line-height: 45px;
    height: 45px;
  }
}

.product_d_action {
  margin-bottom: 10px;
}

.product_d_action ul li {
  margin-right: 30px;
  display: inline-block;
}

.product_d_action ul li:last-child {
  margin-right: 0;
}

.product_d_action ul li a {
  font-size: 13px;
  color: #747474;
  text-transform: capitalize;
}

.product_d_action ul li a:hover {
  color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .product_d_action ul li {
    margin-right: 15px;
  }
}

.product_d_inner {
  border: 1px solid #ddd;
}

.product_d_inner .tab-content {
  padding: 20px 30px 22px;
}

@media only screen and (max-width: 767px) {
  .product_info_button ul {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .product_info_button ul li {
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .product_info_button ul li:last-child {
    margin-bottom: 0;
  }
}

.product_info_button ul li a {
  display: block;
  padding: 10px 30px;
  float: left;
  text-transform: uppercase;
  font-size: 16px;
  background: #ddd;
  color: #242424;
  font-weight: 600;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .product_info_button ul li a {
    padding: 6px 17px;
    margin-right: 0;
    font-size: 13px;
  }
}

.product_info_button ul li a.active {
  background: #bd874a;
  color: #ffffff;
}

.product_info_button ul li a:hover {
  background: #bd874a;
  color: #ffffff;
}

.product_review_form button {
  border: none;
  background: #242424;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 15px;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}

.product_review_form button:hover {
  background: #bd874a;
  color: #ffffff;
}

.product_info_content p {
  color: #555;
}

.product_d_table {
  padding: 10px 0 22px;
}

.product_d_table table {
  border-top: 1px solid #ddd;
  width: 100%;
}

.product_d_table table tbody tr {
  border-bottom: 1px solid #ddd;
}

.product_d_table table tbody tr td {
  padding: 7px 17px;
}

.product_d_table table tbody tr td:first-child {
  border-right: 1px solid #ddd;
  width: 30%;
  font-weight: 700;
}

.product_info_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
}

.product_info_inner .product_ratting {
  margin-bottom: 10px;
}

.product_info_inner .product_ratting p {
  margin-bottom: 5px;
}

.product_info_inner .product_ratting strong {
  margin-top: 10px;
  display: block;
  margin-bottom: 8px;
}

.product_demo {
  margin-left: 30px;
}

.product_review_form h2 {
  font-size: 17px;
  text-transform: uppercase;
  color: #242424;
  font-weight: 600;
}

.product_review_form input {
  border: 1px solid #ddd;
  background: none;
  width: 100%;
  height: 40px;
}

.product_review_form textarea {
  border: 1px solid #ddd;
  background: none;
  height: 120px;
  resize: none;
  width: 100%;
  margin-bottom: 14px;
}

.product_review_form p {
  margin-bottom: 7px;
}

.product_d_info {
  margin-bottom: 52px;
}

@media only screen and (max-width: 767px) {
  .product_d_info {
    margin-bottom: 54px;
  }
}

.s-tab-zoom.owl-carousel .owl-nav {
  display: block;
}

.s-tab-zoom.owl-carousel .owl-nav div {
  position: absolute;
  color: #747474;
  top: 50%;
  transform: translatey(-50%);
  left: 0;
  font-size: 40px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.s-tab-zoom.owl-carousel .owl-nav div:hover {
  color: #bd874a;
}

.s-tab-zoom.owl-carousel .owl-nav div.owl-next {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product-details-tab {
    margin-bottom: 19px;
  }
}

.product-details-tab:hover .s-tab-zoom.owl-carousel .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.single-zoom-thumb {
  margin-top: 20px;
}

.single-zoom-thumb ul li a {
  width: 100%;
}

.related_product {
  padding-bottom: 44px;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .related_product {
    padding-bottom: 47px;
  }
}

.related_product .single_product {
  margin-bottom: 0;
}

.upsell_product {
  padding-bottom: 56px;
}

.upsell_product .product_area {
  padding-bottom: 52px;
  border-bottom: 1px solid #ddd;
}

.upsell_product .single_product {
  margin-bottom: 0;
}

.product_page .footer_top {
  padding-bottom: 53px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_page .footer_top {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product_page .footer_top {
    padding-bottom: 34px;
  }
}

/* 12. product grouped css here */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_grouped {
    margin-bottom: 40px;
  }
}

.grouped_form {
  border: 1px solid #ddd;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grouped_form {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .grouped_form {
    margin-bottom: 17px;
  }
}

.grouped_form table {
  width: 100%;
}

.grouped_form table tbody tr {
  border-bottom: 1px solid #ddd;
}

.grouped_form table tbody tr td {
  border-right: 1px solid #ddd;
  padding: 11px 5px;
  text-align: center;
}

.grouped_form table tbody tr td input[type="checkbox"] {
  width: 20px;
  height: 21px;
}

.grouped_form table tbody tr td input[type="number"] {
  width: 50px;
  background: inherit;
  border: 1px solid #ddd;
  padding: 0 5px;
  height: 40px;
}

.grouped_form table tbody tr td.grouped-product-list.quantity {
  width: 80px;
  text-align: center;
  line-height: 12px;
}

.grouped_form table tbody tr td.grouped-product-list.label {
  min-width: 200px;
  font-weight: 600;
  font-size: 14px;
}

.grouped_form table tbody tr td.grouped-product-list.label a {
  color: #242424;
}

.grouped_form table tbody tr td.grouped-product-list.label a:hover {
  color: #bd874a;
}

.grouped_form table tbody tr td.grouped-product-list.price {
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  min-width: 200px;
}

.grouped_form table tbody tr td.grouped-product-list.price p {
  color: #242424;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}

.grouped_form table tbody tr td.grouped-product-list.price p::before {
  color: #f6f6f6;
  content: '\f058';
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 40px;
}

.grouped_form table tbody tr td:last-child {
  border-right: 0;
}

.grouped_form table tbody tr:last-child {
  border-bottom: 0;
}

.box_quantity.group button {
  margin-left: 0;
}

/*product grouped css end*/
/*variabla product css here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .variable_product {
    margin-bottom: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .variable_product {
    margin-bottom: 58px;
  }
}

@media only screen and (max-width: 767px) {
  .variable_product .product-details-tab {
    margin-bottom: 49px;
  }
}

.product_d_meta span {
  display: block;
  line-height: 18px;
  margin-bottom: 17px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
}

.product_d_meta span:last-child {
  margin-bottom: 0;
}

.product_d_meta span a:hover {
  color: #bd874a;
}

/*product gallery css heer*/
.product_details.gallery {
  margin-bottom: 40px;
}

.product_details.gallery .product_d_right .product_desc p {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_details.gallery {
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .product_details.gallery {
    margin-bottom: 42px;
  }
}

.product_gallery {
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_gallery {
    margin-bottom: 37px;
  }
}

@media only screen and (max-width: 767px) {
  .product_gallery {
    margin-bottom: 47px;
  }
}

@media only screen and (max-width: 479px) {
  .product_gallery {
    margin-bottom: 57px;
  }
}

.product_gallery_thumb {
  width: 45%;
  float: left;
  margin-bottom: 20px;
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_gallery_thumb {
    width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .product_gallery_thumb {
    width: 45%;
    text-align: center;
    float: left;
    margin: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .product_gallery_thumb {
    width: 100%;
    float: inherit;
    margin-right: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 479px) {
  .product_gallery_thumb:last-child {
    margin-bottom: 0;
  }
}

.product_d_gallery {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_d_gallery {
    margin-bottom: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .product_d_gallery {
    margin-bottom: 29px;
  }
}

/*product sidebar css here*/
.product_sidebar {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar {
    margin-bottom: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .row {
    flex-direction: column-reverse;
  }
}

.product_sidebar .upsell_product {
  padding-bottom: 52px;
}

.product_sidebar .upsell_product .product_area {
  padding-bottom: 0;
  border-bottom: 0;
}

.product_sidebar .product_desc p {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .action_button ul li a {
    width: 40px;
    height: 35px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .quick_button {
    bottom: 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .quick_button a {
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .hover_action a {
    width: 40px;
    height: 35px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .product_variant.quantity button {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .product_variant.quantity button {
    padding: 0 20px;
  }
}

.footer_widgets.sidebar_widgets .footer_top {
  padding-bottom: 0;
  border-bottom: 0;
}

.footer_widgets.sidebar_widgets .footer_top_inner {
  padding: 56px 0 52px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_widgets.sidebar_widgets .footer_top_inner {
    padding: 56px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_widgets.sidebar_widgets .footer_top_inner {
    padding: 56px 0 35px;
  }
}

.product_right_sidebar {
  margin-bottom: 53px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_right_sidebar .row {
    flex-direction: row;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_right_sidebar {
    margin-bottom: 43px;
  }
}

@media only screen and (max-width: 767px) {
  .product_right_sidebar {
    margin-bottom: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .product_right_sidebar .product_d_right {
    margin-bottom: 42px;
  }
}

/* 15. cart page css here */
.header_cart_page {
  border-bottom: 1px solid #ddd;
}

.other_bread {
  padding-top: 41px;
  padding-bottom: 41px;
}

.table_desc {
  border: 1px solid #ddd;
  margin-bottom: 59px;
  margin-top: 2px;
}

.table_desc .cart_page table {
  width: 100%;
}

.table_desc .cart_page table thead tr th {
  border-bottom: 3px solid #bd874a;
  border-right: 1px solid #ddd;
  color: #747474;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
}

.table_desc .cart_page table tbody tr td {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  text-align: center;
  padding: 10px;
}

.table_desc .cart_page table tbody tr td.product_thumb {
  min-width: 159px;
}

.table_desc .cart_page table tbody tr td.product_thumb img {
  width: inherit;
}

.table_desc .cart_page table tbody tr td.product_name {
  min-width: 260px;
}

.table_desc .cart_page table tbody tr td.product_name a {
  color: #747474;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}

.table_desc .cart_page table tbody tr td.product_name a:hover {
  color: #bd874a;
}

.table_desc .cart_page table tbody tr td.product_remove {
  min-width: 125px;
}

.table_desc .cart_page table tbody tr td.product_remove a {
  font-size: 20px;
  color: #bd874a;
}

.table_desc .cart_page table tbody tr td.product_remove a:hover {
  color: #bd874a;
}

.table_desc .cart_page table tbody tr td.product-price {
  min-width: 159px;
  color: #bd874a;
  font-size: 18px;
  font-weight: 600;
}

.table_desc .cart_page table tbody tr td.product_total {
  min-width: 159px;
}

.table_desc .cart_page table tbody tr td.product_quantity {
  min-width: 175px;
}

.table_desc .cart_page table tbody tr td.product_quantity input {
  width: 60px;
  height: 40px;
  padding: 0 5px 0 10px;
  background: none;
  border: 1px solid #ddd;
}

.cart_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
  border-right: 0;
}

.cart_submit {
  text-align: right;
  padding: 12px;
}

.cart_submit button {
  background: #242424;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cart_submit button:hover {
  background: #bd874a;
}

.coupon_inner {
  padding: 10px 20px 25px;
}

.coupon_inner p {
  color: #747474;
  font-size: 13px;
  margin-bottom: 20px;
}

.coupon_inner button {
  background: #242424;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.coupon_inner button:hover {
  background: #bd874a;
}

.coupon_inner input {
  border: 1px solid #ddd;
  height: 42px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  color: #747474;
}

@media only screen and (max-width: 767px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

.coupon_inner a {
  display: block;
  text-align: right;
  color: #bd874a;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.coupon_code {
  border: 1px solid #ddd;
}

@media only screen and (max-width: 767px) {
  .coupon_code.left {
    margin-bottom: 59px;
  }
}

.coupon_code h3 {
  color: #ffffff;
  line-height: 37px;
  padding: 5px 15px;
  background: #242424;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .coupon_code h3 {
    line-height: 28px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

.cart_subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart_subtotal p {
  color: #242424;
  font-weight: 600;
  font-size: 14px;
}

.cart_subtotal p.cart_amount {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .cart_subtotal p.cart_amount {
    font-size: 14px;
  }
}

.cart_subtotal p span {
  margin-right: 30px;
}

.checkout_btn {
  text-align: right;
}

.checkout_btn a {
  background: #bd874a;
  color: #ffffff;
  font-size: 14px;
  padding: 3px 14px;
  line-height: 30px;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 0;
}

.checkout_btn a:hover {
  background: #242424;
}

.coupon_area {
  margin-bottom: 60px;
}

.footer_widgets.other_widgets .footer_top {
  padding-bottom: 0;
  border-bottom: 0;
}

.footer_widgets.other_widgets .footer_top_inner {
  padding: 55px 0 53px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_widgets.other_widgets .footer_top_inner {
    padding: 55px 0 59px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_widgets.other_widgets .footer_top_inner {
    padding: 55px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_widgets.other_widgets .footer_top_inner {
    padding: 55px 0 35px;
  }
}

/*cart page css end*/
/* 16. checkout page css here */
.user-actions {
  margin-bottom: 20px;
}

.user-actions h3 {
  font-size: 13px;
  font-weight: 400;
  color: #242424;
  background-color: #f7f6f7;
  padding: 15px 10px;
  border-top: 3px solid #bd874a;
  margin-bottom: 0;
}

.user-actions h3 a {
  color: #bd874a;
}

.checkout_info {
  border: 1px solid #ddd;
  margin-top: 25px;
  padding: 20px 30px;
}

.checkout_info p {
  margin-bottom: 0;
}

.checkout_info a {
  color: #bd874a;
  margin-top: 15px;
  display: block;
}

.form_group {
  margin-bottom: 20px;
}

.form_group label {
  color: #242424;
  font-size: 14px;
  display: block;
  line-height: 18px;
  margin-bottom: 6px;
}

.form_group input {
  border: 1px solid #ddd;
  background: none;
  height: 45px;
  width: 350px;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .form_group input {
    width: 100%;
  }
}

.form_group button {
  display: inline-block;
  width: 80px;
  background: #242424;
  border: 0;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 20px;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
}

.form_group button:hover {
  background: #bd874a;
}

.form_group input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.form_group.group_3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form_group.group_3 label {
  margin-bottom: 0;
  line-height: 34px;
  cursor: pointer;
}

.form_group.group_3 label:hover {
  color: #bd874a;
}

#checkout_coupon input {
  background: none;
  border: 1px solid #ddd;
  width: 200px;
  height: 45px;
  font-size: 12px;
  padding: 0 20px;
  color: #747474;
}

#checkout_coupon button {
  width: 130px;
  margin-left: 12px;
  background: #242424;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 0;
  height: 40px;
  line-height: 40px;
}

@media only screen and (max-width: 767px) {
  #checkout_coupon button {
    margin-top: 20px;
  }
}

#checkout_coupon button:hover {
  background: #bd874a;
}

.mb-20 {
  margin-bottom: 20px;
}

.checkout_form h3 {
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #ffffff;
  background: #242424;
  font-weight: 600;
}

.checkout_form input {
  border: 1px solid #ddd;
  background: none;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  color: #747474;
}

.checkout_form .nice-select {
  width: 100%;
}

.checkout_form .nice-select ul.list {
  width: 100%;
  height: 180px;
  overflow: auto;
}

.checkout_form .nice-select::after {
  top: 56%;
}

.checkout_form label {
  margin-bottom: 6px;
}

.checkout_form label span {
  color: #bd874a;
}

.checkout_form label.righ_0 {
  cursor: pointer;
  font-size: 15px;
  line-height: 27px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #ffffff;
  background: #242424;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .checkout_form label.righ_0 {
    font-size: 13px;
    line-height: 25px;
    padding: 3px 10px;
  }
}

.checkout_form label.righ_0:hover {
  background: #242424;
}

.checkout_form input[type="checkbox"] {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.order_button button {
  cursor: pointer;
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #ffffff;
  background: #bd874a;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0;
}

.order_button button:hover {
  background: #242424;
}

@media only screen and (max-width: 767px) {
  .order-notes {
    margin-bottom: 56px;
  }
}

.order-notes textarea {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 45px;
  max-width: 100%;
  padding: 0 30px 0 20px;
  background: none;
  font-size: 13px;
  resize: none;
  line-height: 45px;
  width: 100%;
  color: #747474;
}

.order-notes label {
  line-height: 13px;
}

.Checkout_section {
  padding-bottom: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Checkout_section {
    padding-bottom: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .Checkout_section {
    padding-bottom: 60px;
  }
}

.order_table {
  margin-bottom: 35px;
}

.order_table table {
  width: 100%;
}

.order_table table thead tr th {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tbody tr td {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr th {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr td {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.panel-default input[type="radio"] {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.panel-default img {
  width: 160px;
}

.order_button button {
  border: 0;
}

.card-body1 {
  margin-bottom: 15px;
}

/*checkout page css end*/
/* 24. wishlist css here */
.table_desc.wishlist table tbody tr:last-child td {
  border-bottom: 0;
}

.table_desc.wishlist table tbody tr td.product_total a {
  background: #bd874a;
  font-size: 12px;
  font-weight: 700;
  height: 38px;
  line-height: 18px;
  padding: 10px 20px;
  color: #ffffff;
  text-transform: uppercase;
}

.table_desc.wishlist table tbody tr td.product_total a:hover {
  background: #242424;
}

.wishlist_share {
  text-align: center;
  padding: 20px 0;
  border: 1px solid #ddd;
}

.wishlist_share h3 {
  font-size: 18px;
  color: #242424;
  font-weight: 700;
  text-transform: capitalize;
}

.wishlist_share ul li {
  display: inline-block;
}

.wishlist_share ul li a {
  padding: 0 10px;
  color: #555;
  display: block;
}

.wishlist_share ul li a:hover {
  color: #bd874a;
}

.wishlist_area {
  padding-bottom: 60px;
}

/*wishlist css end*/
/* 17. contact page css here */
.contact_area {
  margin-bottom: 60px;
}

.contact_message h3 {
  font-size: 25px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .contact_message h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.contact_message p {
  font-size: 14px;
  line-height: 24px;
  color: #242424;
  margin-bottom: 20px;
}

.contact_message ul li {
  padding: 13px 0;
  border-top: 1px solid #ddd;
}

.contact_message ul li:last-child {
  padding-bottom: 0;
}

.contact_message ul li i {
  margin-right: 10px;
}

.contact_message ul li a {
  color: #bd874a;
}

.contact_message ul li a:hover {
  color: #242424;
}

.contact_message label {
  line-height: 18px;
  color: #242424;
  font-weight: 500;
  margin-bottom: 10px;
}

.contact_message input {
  border: 1px solid #ddd;
  height: 45px;
  background: #ffffff;
  width: 100%;
  padding: 0 20px;
  color: #747474;
}

.contact_message textarea {
  height: 170px;
  border: 1px solid #ddd;
  background: #ffffff;
  resize: none;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 20px;
  color: #747474;
}

.contact_message button {
  font-weight: 700;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  text-transform: uppercase;
  border: none;
  background: #bd874a;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.contact_message button:hover {
  background: #242424;
}

@media only screen and (max-width: 767px) {
  .contact_message.content {
    margin-bottom: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_message.content {
    margin-bottom: 52px;
  }
}

.contact_message.form p.form-messege {
  margin-bottom: 0;
}

.contact_map {
  margin-bottom: 60px;
}

.map-area #googleMap {
  height: 460px;
  width: 100%;
}

/*contact page css end*/
/* 19. login page css here */
.account_form h2 {
  font-size: 30px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .account_form h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.account_form form {
  border: 1px solid #ddd;
  padding: 23px 20px 29px;
  border-radius: 5px;
  margin: 24px 0 0;
}

.form-check-input[type=checkbox] {
  width: 17px;
  padding: 0;
  height: 17px;
}

.account_form label {
  font-size: 15px;
  font-weight: 400;
  color: #242424;
  cursor: pointer;
  line-height: 12px;
  margin-bottom: 12px;
}

.account_form label:hover {
  color: #bd874a;
}

.account_form input {
  border: 1px solid #ddd;
  height: 49px;
  max-width: 100%;
  padding: 0 20px;
  background: none;
  width: 100%;
  border-radius: 7px;
}

.account_form button {
  background: #bd874a;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 34px;
  line-height: 21px;
  padding: 5px 20px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-left: 20px;
  border-radius: 20px;
}

.account_form button:hover {
  background: #242424;
}

.login_submit label input[type="checkbox"] {
  width: 15px;
  height: 13px;
  margin-right: 3px;
}

.login_submit {
  text-align: right;
}

.login_submit a {
  font-size: 13px;
  color: #bd874a;
  float: left;
  line-height: 39px;
}

@media only screen and (max-width: 767px) {
  .login_submit a {
    float: none;
    line-height: 18px;
    display: block;
    margin-bottom: 20px;
  }
}

.customer_login {
  padding-bottom: 59px;
}

.account_form p {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .account_form.register {
    margin-top: 57px;
  }
}

/*login page css end */
/* 18. faq page css here */
.faq_content_area {
  padding-bottom: 53px;
}

.accordion_area {
  padding-bottom: 60px;
}

.card.card_dipult {
  border: none;
  margin-bottom: 10px;
}

.card.card_dipult:last-child {
  margin-bottom: 0;
}

.card-header.card_accor {
  padding: 0;
  border: none;
}

.card-header.card_accor button {
  height: 45px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  white-space: initial;
}

@media only screen and (max-width: 767px) {
  .card-header.card_accor button {
    height: 60px;
  }
}

.card-header.card_accor button i {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .card-header.card_accor button i {
    right: 10px;
  }
}

.card-header.card_accor button.btn-link {
  border: 1px solid #bd874a;
  width: 100%;
  text-align: left;
  color: #bd874a;
  font-size: 13px;
}

.card-header.card_accor button.btn-link.collapsed {
  background: #f2f2f2;
  border: 1px solid #ddd;
  width: 100%;
  text-align: left;
  color: #242424;
}

.accordion_area .collapse.show {
  border-left: 1px solid #bd874a;
  border-bottom: 1px solid #bd874a;
  border-right: 1px solid #bd874a;
}

.card-header.card_accor button.btn-link i.fa-plus {
  display: none;
}

.card-header.card_accor button.btn-link.collapsed i.fa-plus {
  display: block;
}

.card-header.card_accor button.btn-link.collapsed i.fa-minus {
  display: none;
}

.faq_content_wrapper h4 {
  font-size: 18px;
  color: #242424;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 18px;
}

@media only screen and (max-width: 767px) {
  .faq_content_wrapper h4 {
    font-size: 14px;
    line-height: 24px;
  }
}

/*faq page css end*/
/*  20. my account css here */
@media only screen and (max-width: 767px) {
  .dashboard_tab_button {
    margin-bottom: 20px;
  }
}

.dashboard_tab_button ul li {
  margin-bottom: 5px;
}

.dashboard_tab_button ul li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
  background: #242424;
  border-radius: 3px;
}

.dashboard_tab_button ul li a:hover {
  background: #bd874a;
  color: #ffffff;
}

.dashboard_tab_button ul li a.active {
  background: #bd874a;
}

.main_content_area {
  padding-bottom: 55px;
}

@media only screen and (max-width: 767px) {
  .main_content_area {
    padding-bottom: 54px;
  }
}

.dashboard_content h3 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 500;
  color: #242424;
  margin-bottom: 15px;
}

.dashboard_content h4 {
  font-size: 20px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dashboard_content a {
  color: #bd874a;
  font-weight: 500;
}

.dashboard_content p a {
  color: #bd874a;
  font-weight: 500;
}

.table-responsive table thead {
  background: #f2f2f2;
}

.table-responsive table thead tr th {
  text-align: center;
}

.table-responsive table tbody tr td {
  border-right: 1px solid #ddd;
  color: #242424;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  min-width: 150px;
}

.table-responsive table tbody tr td:last-child a {
  color: #bd874a;
}

.table-responsive .table {
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.dashboard_content address {
  font-weight: 500;
  color: #242424;
}

.input-radio span input[type="radio"],
.account_login_form form span input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.input-radio span {
  color: #242424;
  font-weight: 500;
  padding-right: 10px;
}

.account_login_form form input {
  border: 1px solid #ddd;
  background: none;
  height: 40px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
  color: #747474;
}

/*my account css end*/
/* 12. about page css here */
/*welcome about section css here*/
.welcome_about_section {
  margin-bottom: 94px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .welcome_about_section {
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .welcome_about_section {
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_about_section {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome_about_section {
    margin-bottom: 55px;
  }
}

.welcome_about_inner {
  text-align: center;
}

.welcome_about_inner img {
  margin-bottom: 31px;
}

@media only screen and (max-width: 767px) {
  .welcome_about_inner img {
    margin-bottom: 20px;
  }
}

.welcome_about_inner p {
  font-size: 16px;
  line-height: 30px;
  padding: 0 70px;
  margin-bottom: 27px;
}

@media only screen and (max-width: 767px) {
  .welcome_about_inner p {
    padding: 0;
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 18px;
  }
}

.welcome_about_inner p.author {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #242424;
  margin-bottom: 0;
  line-height: 20px;
}

.welcome_about_inner p.author span {
  color: #747474;
}

.welcome_eight {
  margin-bottom: 67px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_eight {
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome_eight {
    margin-bottom: 50px;
  }
}

/*welcome about section css end*/
/* 12. about page css here */
.header_about {
  border-bottom: 1px solid #ddd;
}

.about_section {
  padding-bottom: 60px;
}

.about_section.section_two {
  padding: 0 194px;
}

.about_section.section_two .about_thumb {
  text-align: center;
}

.about_section.section_two .about_content {
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_content {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about_content {
    margin-bottom: 30px;
  }
}

.about_content h1 {
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
  color: #242424;
  font-size: 30px;
  margin-bottom: 19px;
}

@media only screen and (max-width: 767px) {
  .about_content h1 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}

.about_content p {
  color: #747474;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .about_content p {
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .view__work {
    margin-top: 18px;
  }
}

.view__work a {
  color: #242424;
  border: 1px solid #242424;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  padding: 10px 15px;
  display: inline-block;
  text-transform: uppercase;
}

.view__work a:hover {
  background: #bd874a;
  color: #fff;
  border-color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .view__work a {
    padding: 7px 9px;
    height: 33px;
    font-size: 12px;
  }
}

.counterup_section {
  padding-bottom: 30px;
}

.counter_img {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .counter_img {
    margin-right: 10px;
  }
}

.single_counterup {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f3f3f3;
  padding: 88px 0 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.about_section.section_two {
  padding: 25px 20px 18px;
}

.counter_info h2 {
  font-size: 30px;
  font-weight: 500;
  color: #242424;
  margin-bottom: 5px;
  line-height: 33px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .counter_info h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

.counter_info p {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  color: #747474;
  margin-bottom: 0;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .counter_info p {
    font-size: 11px;
  }
}

.about_progressbar {
  padding-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_progressbar {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_progressbar {
    padding-bottom: 70px;
  }
}

.about_progressbar.about_prog {
  background-color: #bd874a;
  border-radius: 3px;
}

.about_progressbar.about_prog span.progress_persent {
  position: absolute;
  left: 50px;
  text-transform: uppercase;
}

.about_progressbar.porgress_two {
  padding: 5px 20px 35px;
}

@media only screen and (max-width: 767px) {
  .progressbar_inner {
    margin-bottom: 40px;
  }
}

.progressbar_inner h2 {
  font-size: 25px;
  color: #242424;
  margin-bottom: 40px;
  font-weight: 400;
  line-height: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .progressbar_inner h2 {
    font-size: 21px;
    margin-bottom: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .progressbar_inner h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.progress_skill {
  position: relative;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .progress_skill {
    margin-bottom: 33px;
  }
}

.progress_skill:last-child {
  margin-bottom: 0;
}

.progress_skill span.progress_discount {
  position: absolute;
  left: -2px;
  background: #242424;
  border-radius: 100%;
  border: 3px solid #bd874a;
  color: #fff;
  width: 40px;
  height: 40px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  text-align: center;
  line-height: 35px;
  z-index: 9;
}

.progress_skill .progress-bar {
  background: #bd874a;
}

.progress_skill.one .progress-bar {
  width: 60%;
}

.progress_skill.two .progress-bar {
  width: 90%;
}

.progress_skill.three .progress-bar {
  width: 70%;
}

.progress_skill.four .progress-bar {
  width: 80%;
}

/*about page css end*/
/* 22. services page css here */
.srrvices_gallery {
  padding-bottom: 30px;
}

.services_content h3 {
  font-size: 15px;
  color: #242424;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 11px;
  line-height: 14px;
}

.services_content p {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

.services_thumb {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .services_thumb img {
    width: 100%;
  }
}

.single_services {
  margin-bottom: 25px;
}

.our_services {
  padding: 53px 0 24px;
  background: #ECECEC;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our_services {
    padding: 53px 0 28px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .our_services {
    padding: 52px 0 26px;
    margin-bottom: 60px;
  }
}

.services_item {
  margin-bottom: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.services_title {
  text-align: center;
  margin-bottom: 35px;
}

.services_title h2 {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 11px;
  color: #242424;
}

.services_icone {
  margin-right: 15px;
}

.services_icone i {
  font-size: 35px;
  line-height: 46px;
  color: #bd874a;
}

.services_desc h3 {
  font-size: 13px;
  color: #242424;
  font-weight: 500;
}

.services_desc p {
  color: #747474;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services_section_thumb {
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .services_section_thumb {
    margin-bottom: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .unlimited_services {
    margin-bottom: 59px;
  }
}

@media only screen and (max-width: 767px) {
  .unlimited_services {
    margin-bottom: 59px;
  }
}

.unlimited_services_content {
  text-align: center;
}

.unlimited_services_content h1 {
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
  color: #242424;
  font-size: 25px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .unlimited_services_content h1 {
    line-height: 25px;
    font-size: 20px;
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .unlimited_services_content h1 {
    line-height: 21px;
    font-size: 20px;
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .unlimited_services_content h1 {
    line-height: 20px;
    font-size: 18px;
    margin-bottom: 6px;
  }
}

.unlimited_services_content p {
  color: #747474;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .unlimited_services_content p {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .unlimited_services_content p {
    font-size: 13px;
    margin-bottom: 15px;
  }
}

.priceing_table {
  background: #ECECEC;
  padding: 60px 0 30px;
}

.single_priceing {
  background: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
  box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
  text-align: center;
  margin-bottom: 30px;
}

.priceing_title {
  padding: 20px;
  background: #bd874a;
}

.priceing_title h1 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

.priceing_list {
  padding: 0 20px 30px;
}

.priceing_list h1 {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 36px 0 24px;
  margin: 0;
  line-height: 20px;
  color: #747474;
}

.priceing_list h1 span {
  color: #242424;
  font-size: 40px;
}

.priceing_list ul li {
  color: #747474;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  line-height: 24px;
}

.priceing_list ul li:first-child {
  border-top: 1px solid #ddd;
}

.priceing_list a {
  margin: 30px 0 0;
  line-height: 40px;
  padding: 0 20px;
  border: 1px solid #242424;
  color: #242424;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.priceing_list a.list_button,
.priceing_list a:hover {
  background: #bd874a;
  border-color: #bd874a;
  color: #fff;
}

.advantages_ecommerce {
  padding: 56px 0 54px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advantages_ecommerce {
    padding: 58px 0 57px;
  }
}

@media only screen and (max-width: 767px) {
  .advantages_ecommerce {
    padding: 58px 0 60px;
  }
}

.advantages_ecommerce h3 {
  font-size: 25px;
  color: #242424;
  font-weight: 500;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advantages_ecommerce h3 {
    font-size: 20px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .advantages_ecommerce h3 {
    font-size: 14px;
    line-height: 20px;
  }
}

.advantages_ecommerce p {
  font-size: 14px;
  color: #242424;
  font-style: italic;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advantages_ecommerce p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .advantages_ecommerce p {
    font-size: 13px;
  }
}

.advantages_button {
  text-align: center;
}

.advantages_button a {
  font-size: 14px;
  color: #242424;
  padding: 10px 20px;
  display: inline-block;
  border: 2px solid #242424;
}

.advantages_button a:hover {
  background: #bd874a;
  color: #fff;
  border-color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .advantages_button a {
    padding: 5px 11px;
    font-size: 13px;
    margin-top: 20px;
  }
}

/*services page css end*/
/* 21. portfolio page css here */
.portfolio_button {
  text-align: center;
  margin-bottom: 35px;
  background: #ddd;
  padding: 35px 0;
}

@media only screen and (max-width: 767px) {
  .portfolio_button {
    padding: 35px 0 28px;
  }
}

.portfolio_button button {
  color: #747474;
  background: #ffffff;
  border-right: 2px solid #ddd;
  line-height: 45px;
  padding: 0 30px;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
  display: inline-block;
  border: none;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.portfolio_button button:hover {
  background: #bd874a;
  color: #ffffff;
}

.portfolio_button button.active {
  background: #bd874a;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .portfolio_button button {
    margin-bottom: 7px;
    margin-left: 5px;
    line-height: 38px;
    padding: 0 26px;
  }
}

.portfolio_thumb {
  position: relative;
}

.portfolio_thumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #bd874a;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}

.portfolio_popup {
  position: absolute;
  top: 50%;
  left: 45%;
  text-align: center;
  font-size: 18px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
}

.portfolio_popup a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  color: #747474;
  transform: scale(0);
  border-radius: 50%;
  background: #ddd;
}

.portfolio_popup a:hover {
  color: #ffffff;
  background: #000000;
}

.single_portfolio_inner {
  margin-bottom: 30px;
  overflow: hidden;
}

.single_portfolio_inner:hover .portfolio_thumb::before {
  opacity: 0.5;
  visibility: visible;
  transform: scale(1);
}

.single_portfolio_inner:hover .portfolio_popup {
  opacity: 1;
  visibility: visible;
}

.single_portfolio_inner:hover .portfolio_popup a {
  transform: scale(1);
}

.single_portfolio_inner:hover .portfolio__content {
  left: 30px;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_portfolio_inner:hover .portfolio__content {
    left: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .single_portfolio_inner:hover .portfolio__content {
    left: 13px;
  }
}

.portfolio__content {
  position: absolute;
  bottom: 30px;
  left: -85px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__content {
    bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__content {
    bottom: 20px;
  }
}

.portfolio__content h3 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio__content h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio__content h3 {
    font-size: 14px;
  }
}

.portfolio__content h3 a {
  color: #ffffff;
}

.portfolio__content h3 a:hover {
  color: #242424;
}

.portfolio_section_area {
  margin-bottom: 30px;
}

.blog_details.portfolio_details {
  margin-bottom: 59px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details.portfolio_details {
    margin-bottom: 49px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details.portfolio_details {
    margin-bottom: 49px;
  }
}

.blog_details.portfolio_details .comments_form {
  margin-bottom: 0;
}

.portfolio_widgets .footer_top {
  padding-bottom: 0;
  border-bottom: 0;
}

.portfolio_widgets .footer_top_inner {
  padding: 55px 0 52px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio_widgets .footer_top_inner {
    padding: 55px 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio_widgets .footer_top_inner {
    padding: 55px 0 39px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio_widgets .footer_top_inner {
    padding: 55px 0 35px;
  }
}

/* 13. blog page css here */
.header_blog {
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_sidebar {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_sidebar {
    margin-bottom: 50px;
  }
}

.sidebar_widget h3.widget_title {
  padding: 0 0 8px;
  border-bottom: 1px solid #ddd;
  color: #242424;
  font-size: 20px;
  margin: 0 0 15px;
  font-weight: 500;
  line-height: 24px;
}

.sidebar_widget.search_form {
  margin-bottom: 45px;
}

.sidebar_widget.search_form form {
  position: relative;
}

.sidebar_widget.search_form form input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 20px;
  color: #747474;
  border: 1px solid #ddd;
}

.sidebar_widget.search_form form button {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  background: inherit;
  border: 0;
  color: #747474;
  font-size: 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.sidebar_widget.search_form form button:hover {
  color: #bd874a;
}

.sidebar_widget.widget_categories {
  border: 1px solid #ddd;
  margin-bottom: 39px;
}

.sidebar_widget.widget_categories h3.widget_title {
  padding: 12px 15px;
}

.sidebar_widget.widget_categories ul {
  padding: 0 0 19px 15px;
}

.sidebar_widget.widget_categories ul li {
  line-height: 30px;
}

.sidebar_widget.widget_categories ul li a {
  color: #747474;
}

.sidebar_widget.widget_categories ul li a:hover {
  color: #bd874a;
}

.sidebar_widget.recent_post {
  margin-bottom: 40px;
}

.sidebar_widget.tags_widget ul li {
  display: inline-block;
}

.sidebar_widget.tags_widget ul li a {
  padding: 5px 20px;
  border: 1px solid #ddd;
  display: block;
  margin: 0 10px 10px 0;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 20px;
  border-radius: 20px;
  color: #747474;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar_widget.tags_widget ul li a {
    padding: 5px 17px;
  }
}

.sidebar_widget.tags_widget ul li a:hover {
  background: #bd874a;
  color: #ffffff;
  border-color: #bd874a;
}

.blog_grid {
  overflow: hidden;
  margin-bottom: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid {
    margin-bottom: 59px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_grid {
    margin-bottom: 54px;
  }
}

.blog_grid .blog_thumb {
  float: left;
  width: 40%;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_thumb {
    float: inherit;
    width: 100%;
    margin-bottom: 21px;
  }
}

.blog_grid .blog_thumb_active {
  float: left;
  width: 40%;
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_thumb_active {
    float: inherit;
    width: 100%;
    margin-bottom: 20px;
  }
}

.blog_grid .blog_thumb_active .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  width: 30px;
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  height: 30px;
  background: #ddd;
  border-radius: 100%;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.blog_grid .blog_thumb_active .owl-nav div.owl-next {
  right: 0;
  left: auto;
}

.blog_grid .blog_thumb_active .owl-nav div:hover {
  background: #bd874a;
  color: #ffffff;
}

.blog_grid .blog_content {
  float: right;
  padding-left: 40px;
  width: 60%;
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_content {
    float: inherit;
    width: 100%;
    padding-left: 0;
  }
}

.blog_grid .blog_content h3.post_title {
  font-size: 20px;
  text-transform: inherit;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid .blog_content h3.post_title {
    font-size: 18px;
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_content h3.post_title {
    font-size: 15px;
  }
}

.blog_grid .blog_content h3.post_title a {
  color: #242424;
}

.blog_grid .blog_content h3.post_title a:hover {
  color: #bd874a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid .blog_content .post_date {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid .blog_content .post_date span.day {
    font-size: 40px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid .blog_content a.read_more {
    margin-bottom: 5px;
  }
}

.blog_grid.video .blog_thumb {
  height: 240px;
}

.sidebar_post {
  margin-bottom: 30px;
  display: flex;
}

.sidebar_post:last-child {
  margin-bottom: 0;
}

.sidebar_post .post_img {
  width: 55px;
  margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar_post .post_img {
    width: 49px;
    margin-right: 8px;
  }
}

.sidebar_post .post_text h3 {
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 3px;
  line-height: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar_post .post_text h3 {
    font-size: 13px;
  }
}

.sidebar_post .post_text h3 a {
  color: #747474;
}

.sidebar_post .post_text h3 a:hover {
  color: #bd874a;
}

.sidebar_post .post_text span {
  color: #7e7e7e;
  font-size: 12px;
}

.blog_grid.audio .blog_content h3.post_title {
  margin: 0 0 17px;
}

.blog_grid.audio .blog_content .blog_aduio_icone audio {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_reverse .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .blog_reverse .row {
    flex-direction: column-reverse;
  }
}

.blog_page {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_page {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_page {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_page .sidebar_widget.search_form {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_page .sidebar_widget.search_form {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_page .sidebar_widget.widget_categories {
    margin-bottom: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_page .sidebar_widget.widget_categories {
    margin-bottom: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_page .sidebar_widget.recent_post {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_page .sidebar_widget.recent_post {
    margin-bottom: 55px;
  }
}

.post_date {
  margin-bottom: 12px;
}

.post_date span.day {
  color: #a9a9a9;
  font-size: 48px;
  line-height: 40px;
}

.post_date span.month {
  font-size: 14px;
  color: #a9a9a9;
}

.blog_content p.post_desc {
  color: #a9a9a9;
  font-size: 13px;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 7px;
}

.blog_content a.read_more {
  color: #747474;
  text-decoration: underline;
  font-style: italic;
  margin-bottom: 7px;
}

.blog_content a.read_more:hover {
  color: #bd874a;
}

.post_meta span {
  font-size: 13px;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 5px;
  color: #747474;
}

.post_meta span a {
  color: #747474;
}

.post_meta span a:hover {
  color: #bd874a;
}

.blog_pagination {
  margin-bottom: 60px;
}

.blog_pagination .pagination {
  border: 1px solid #ddd;
  padding: 8px 10px;
  justify-content: center;
  margin-top: 0;
}

.footer_widgets.blog_widgets .footer_top {
  padding-bottom: 0;
  border-bottom: 0;
}

.footer_widgets.blog_widgets .footer_top_inner {
  padding: 56px 0 52px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_widgets.blog_widgets .footer_top_inner {
    padding: 56px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_widgets.blog_widgets .footer_top_inner {
    padding: 56px 0 35px;
  }
}

/*blog page css end*/
/*blog fullwidth css here*/
.blog_fullwidth {
  margin-bottom: 53px;
}

@media only screen and (max-width: 767px) {
  .blog_fullwidth {
    margin-bottom: 54px;
  }
}

.blog_fullwidth .blog_thumb {
  float: none;
  width: 100%;
  margin-bottom: 23px;
}

.blog_fullwidth .blog_thumb::before {
  display: none;
}

.blog_fullwidth .blog_thumb img {
  width: 100%;
}

.blog_fullwidth .blog_grid {
  margin-bottom: 53px;
}

.blog_fullwidth .blog_grid:last-child {
  margin-bottom: 0;
}

.blog_fullwidth .blog_grid .blog_content {
  float: none;
  width: 100%;
  padding-left: 0;
}

.blog_fullwidth .blog_grid .blog_thumb_active {
  float: none;
  width: 100%;
  margin-bottom: 23px;
}

.blog_fullwidth .blog_grid.video .blog_thumb {
  height: 500px;
}

@media only screen and (max-width: 767px) {
  .blog_fullwidth .blog_grid.video .blog_thumb {
    height: 200px;
  }
}

/*blog fullwidth css end*/
/* 14. blog details css here */
.blog_info_inner {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog_info_inner {
    flex-direction: column;
  }
}

.blog_info_inner .post__date {
  background: #bd874a;
  color: #ffffff;
  font-weight: 700;
  padding: 15px 0 15px;
  position: relative;
  text-align: center;
  min-width: 70px;
  max-height: 75px;
  display: block;
  margin-right: 25px;
}

@media only screen and (max-width: 767px) {
  .blog_info_inner .post__date {
    display: inline-block;
    width: 70px;
    margin-bottom: 42px;
  }
}

.blog_info_inner .post__date::before {
  background: #bd874a;
  bottom: -58px;
  content: "";
  display: block;
  height: 58px;
  position: absolute;
  right: 0;
  width: 5px;
}

@media only screen and (max-width: 767px) {
  .blog_info_inner .post__date::before {
    display: none;
  }
}

.blog_info_inner .post__date span {
  display: block;
  line-height: 22px;
  text-transform: uppercase;
  font-size: 12px;
}

.blog_info_inner .post__date span.day {
  font-size: 25px;
  line-height: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details_wrapper {
    margin-bottom: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details_wrapper {
    margin-bottom: 15px;
  }
}

.blog_details_wrapper .blog__thumb {
  margin-bottom: 44px;
}

.blog_details_wrapper .post_meta {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 7px 0;
  margin-bottom: 36px;
}

.blog_details_wrapper .post_meta span {
  font-style: normal;
  color: #747474;
  text-transform: uppercase;
}

.blog_details_wrapper .post_content p {
  color: #747474;
  line-height: 24px;
  margin-bottom: 10px;
  font-size: 15px;
}

.blog_details_wrapper .post_content blockquote {
  background: #e0e0e0;
  margin: 20px 0;
  padding: 30px 45px;
  font-style: italic;
}

.blog_details_wrapper .social_sharing {
  display: flex;
  padding: 5px 0 40px;
}

@media only screen and (max-width: 767px) {
  .blog_details_wrapper .social_sharing {
    flex-direction: column;
    padding: 5px 0 42px;
  }
}

.blog_details_wrapper .social_sharing h3 {
  color: #242424;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 50px;
}

@media only screen and (max-width: 767px) {
  .blog_details_wrapper .social_sharing h3 {
    margin-bottom: 12px;
    font-size: 17px;
  }
}

.blog_details_wrapper .social_sharing ul li {
  display: inline-block;
}

.blog_details_wrapper .social_sharing ul li a {
  font-size: 17px;
  padding: 0 25px;
  color: #747474;
  display: block;
  line-height: 30px;
  border-left: 1px solid #ddd;
}

.blog_details_wrapper .social_sharing ul li a:hover {
  color: #bd874a;
}

@media only screen and (max-width: 767px) {
  .blog_details_wrapper .social_sharing ul li a {
    padding: 0 17px;
    font-size: 15px;
    line-height: 23px;
  }
}

.blog_details_wrapper .social_sharing ul li:first-child a {
  padding-left: 0;
  border-left: 0;
}

.post_header h3 {
  padding: 0 0 12px;
  color: #242424;
  font-size: 28px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .post_header h3 {
    font-size: 22px;
  }
}

.author_box {
  display: flex;
  margin-bottom: 40px;
}

.author_box .author_img {
  margin-right: 20px;
  min-width: 70px;
}

@media only screen and (max-width: 767px) {
  .author_box .author_img {
    margin-right: 13px;
    min-width: 40px;
  }
}

.author_box .author_desc h3 {
  color: #242424;
  font-size: 20px;
  margin: 0 0 8px;
  font-weight: 500;
  line-height: 20px;
}

@media only screen and (max-width: 767px) {
  .author_box .author_desc h3 {
    font-size: 16px;
  }
}

.author_box .author_desc h3 a {
  color: #242424;
}

.author_box .author_desc h3 a:hover {
  color: #bd874a;
}

.author_box .author_desc p {
  color: #747474;
  line-height: 21px;
  font-size: 14px;
}

.comments_box {
  margin-bottom: 36px;
}

.comments_box h3 {
  border-top: 1px solid #ddd;
  color: #242424;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 20px;
  margin: 0 0 22px;
}

@media only screen and (max-width: 767px) {
  .comments_box h3 {
    font-size: 17px;
  }
}

.comment_list {
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.comment_list:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.comment_list .comment-author-thumb {
  display: inline-block;
  float: left;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment-author-thumb {
    width: 40px;
  }
}

.comment_list .comment_content {
  padding-left: 70px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment_content {
    padding-left: 50px;
  }
}

.comment_list .comment_content h5 {
  font-size: 17px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 3px;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment_content h5 {
    font-size: 15px;
  }
}

.comment_list .comment_content h5 a {
  color: #242424;
}

.comment_list .comment_content h5 a:hover {
  color: #bd874a;
}

.comment_list .comment_content span {
  color: #747474;
  line-height: 18px;
  margin-bottom: 7px;
  font-size: 14px;
}

.comment_list .comment_content p {
  margin-bottom: 0;
  font-size: 15px;
  color: #747474;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment_content p {
    font-size: 13px;
  }
}

.comment_reply {
  position: absolute;
  top: 20px;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .comment_reply {
    top: 0;
  }
}

.comment_reply a {
  color: #bd874a;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.comment_reply a:hover {
  color: #242424;
}

@media only screen and (max-width: 767px) {
  .comment_reply a {
    font-size: 12px;
  }
}

.comments_form {
  margin-bottom: 38px;
}

.comments_form h3 {
  border-top: 1px solid #ddd;
  color: #242424;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 19px;
  margin: 0 0 22px;
}

@media only screen and (max-width: 767px) {
  .comments_form h3 {
    font-size: 17px;
    margin: 0 0 13px;
  }
}

.comments_form p {
  color: #747474;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comments_form form .row {
    flex-direction: row;
  }
}

.comments_form form label {
  color: #242424;
  font-size: 13px;
  line-height: 17px;
}

.comments_form form textarea {
  width: 100%;
  height: 130px;
  background: #ffffff;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  resize: none;
  padding: 10px;
}

.comments_form form input {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  padding: 0 20px;
}

.comments_form form button {
  border: 0;
  line-height: 36px;
}

.comments_form form button:hover {
  background: #242424;
}

.comment_list.comment_border {
  padding-left: 50px;
}

@media only screen and (max-width: 767px) {
  .comment_list.comment_border {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related_posts .row {
    flex-direction: row !important;
  }
}

@media only screen and (max-width: 767px) {
  .related_posts .row {
    flex-direction: column !important;
  }
}

.related_posts h3 {
  color: #242424;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .related_posts h3 {
    font-size: 17px;
    margin-bottom: 15px;
  }
}

.related_posts_thumb {
  margin-bottom: 13px;
}

.related_posts_content h3 {
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .related_posts_content h3 {
    font-size: 15px;
    margin-bottom: 3px;
  }
}

.related_posts_content h3 a {
  color: #242424;
}

.related_posts_content h3 a:hover {
  color: #bd874a;
}

.related_posts_content span {
  color: #242424;
  font-size: 13px;
  line-height: 17px;
}

.blog_details {
  margin-bottom: 53px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details .blog_sidebar {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details .blog_sidebar {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details .blog_sidebar .sidebar_widget h3.widget_title {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .single_related_posts {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_related_posts.column_3 {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .single_related_posts.column_3 {
    margin-bottom: 0;
  }
}

/*blog details css end*/
/*  26. modal css here */
.modal-dialog.modal-dialog-centered {
  min-width: 1000px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 90%;
  }
}

.modal_tab_img {
  text-align: center;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ddd;
}

.modal_tab_img img {
  margin: 0 auto;
}

.modal_tab_button ul li a {
  padding: 0;
  border: 1px solid #ddd;
  margin: 0 2px;
}

.modal_tab_button ul li a img {
  width: 100%;
}

.modal_title h2 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 14px;
  color: #242424;
  line-height: 26px;
}

.modal_social h2 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
  color: #242424;
  line-height: 20px;
}

.modal_social ul li {
  display: inline-block;
  margin-right: 3px;
}

.modal_social ul li a {
  text-transform: uppercase;
  display: inline-block;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
}

.modal_social ul li.facebook a {
  background: #3c5b9b;
}

.modal_social ul li.facebook a:hover {
  background: #bd874a;
}

.modal_social ul li.twitter a {
  background: #1DA1F2;
}

.modal_social ul li.twitter a:hover {
  background: #bd874a;
}

.modal_social ul li.pinterest a {
  background: #BD081B;
}

.modal_social ul li.pinterest a:hover {
  background: #bd874a;
}

.modal_social ul li.google-plus a {
  background: #DC5043;
}

.modal_social ul li.google-plus a:hover {
  background: #bd874a;
}

.modal_social ul li.linkedin a {
  background: #010103;
}

.modal_social ul li.linkedin a:hover {
  background: #bd874a;
}

.modal_price {
  margin-bottom: 12px;
}

.modal_price span {
  font-weight: 600;
  color: #bd874a;
  font-size: 16px;
}

.modal_price span.old_price {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 5px;
}

.modal_description p {
  line-height: 24px;
  font-size: 15px;
  color: #747474;
  margin: 0;
}

.variants_size h2,
.variants_color h2 {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 7px;
  line-height: 20px;
}

.variants_size.mb-15,
.mb-15.variants_color {
  margin-bottom: 24px;
}

.variants_size .select_option,
.variants_color .select_option {
  width: 100%;
  border-radius: 0;
  margin-bottom: 25px;
}

.variants_size .select_option .list,
.variants_color .select_option .list {
  width: 100%;
  border-radius: 0;
}

.modal_add_to_cart {
  margin-bottom: 25px;
}

.modal_add_to_cart form input {
  width: 95px;
  border: 1px solid #ddd;
  background: none;
  padding: 0 10px;
  height: 45px;
}

@media only screen and (max-width: 767px) {
  .modal_add_to_cart form input {
    width: 75px;
  }
}

.modal_add_to_cart form button {
  background: none;
  border: 1px solid #242424;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
  height: 45px;
  width: 230px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  background: #242424;
  color: #ffffff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .modal_add_to_cart form button {
    width: 130px;
  }
}

.modal_add_to_cart form button:hover {
  background: #bd874a;
  color: #ffffff;
  border-color: #bd874a;
}

.modal_body {
  padding: 0 6px 30px;
}

@media only screen and (max-width: 767px) {
  .modal_body {
    padding: 0 6px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modal_body .modal_tab {
    margin-bottom: 60px;
  }
}

.modal-content {
  border-radius: 0;
}

.modal-content button.close {
  position: relative;
  left: 94%;
  width: 35px;
  height: 35px;
  display: block;
  border: 1px solid #ddd;
  top: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .modal-content button.close {
    left: 83%;
    width: 29px;
    height: 32px;
    top: 4px;
    margin-bottom: 14px;
  }
}

.product_navactive.owl-carousel {
  padding: 0 20px;
}

.product_navactive.owl-carousel .owl-nav>div {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: 0;
  font-size: 35px;
  color: #a9a9a9;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product_navactive.owl-carousel .owl-nav>div:hover {
  color: #bd874a;
}

.product_navactive.owl-carousel .owl-nav>div.owl-next {
  right: 0;
  left: auto;
}

.product_navactive.owl-carousel:hover .owl-nav>div {
  opacity: 1;
}

.modal_add_to_cart.mb-15 {
  margin-bottom: 28px;
}

.modal_description.mb-15 {
  margin-bottom: 19px;
}

.nav.product_navactive.owl-carousel .owl-dots {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -35px;
}

.nav.product_navactive.owl-carousel .owl-dots .owl-dot {
  width: 12px;
  height: 12px;
  background: #a9a9a9;
  display: inline-block;
  margin: 0 3px;
  border-radius: 50%;
}

.nav.product_navactive.owl-carousel .owl-dots .owl-dot.active {
  background: #bd874a;
}

/*modal css end*/
/* 25. newsletter popup css here */
.newletter-popup {
  background: #fff;
  top: 50% !important;
  margin-top: -179px;
  position: fixed !important;
  padding: 50px;
  text-align: center;
  display: none;
}

@media only screen and (max-width: 767px) {
  .newletter-popup {
    padding: 30px 20px;
    width: 95%;
  }
}

#boxes .newletter-title h2 {
  font-size: 30px;
  text-transform: uppercase;
  color: #343434;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  #boxes .newletter-title h2 {
    font-size: 22px;
    margin: 0 0 10px;
  }
}

#boxes .box-content label {
  font-weight: 400;
  font-size: 14px;
}

#boxes .box-content .newletter-label {
  width: 70%;
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  #boxes .box-content .newletter-label {
    width: 100%;
  }
}

#frm_subscribe form {
  width: 340px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form {
    width: 100%;
  }
}

#frm_subscribe form input {
  background: #EBEBEB;
  padding: 0 122px 0 20px;
  height: 45px;
  border: 0;
  width: 100%;
  color: #747474;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form input {
    padding: 0 104px 0 20px;
  }
}

#frm_subscribe form a.theme-btn-outlined {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 110px;
  background: #bd874a;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  line-height: 45px;
}

#frm_subscribe form a.theme-btn-outlined:hover {
  background: #242424;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form a.theme-btn-outlined {
    width: 90px;
  }
}

#boxes .box-content .subscribe-bottom {
  margin-top: 20px;
}

#boxes .box-content .subscribe-bottom label {
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  margin-left: 5px;
}

#boxes .box-content .subscribe-bottom #newsletter_popup_dont_show_again {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

#boxes #frm_subscribe #notification {
  color: #f00;
}

#boxes #frm_subscribe #notification .success {
  color: #67D7BB;
}

#popup2 {
  position: absolute;
  right: 1px;
  top: 1px;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  text-transform: capitalize;
  padding: 6px 12px;
  background: #242424;
  font-weight: 600;
  line-height: 17px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
}

#popup2:hover {
  background: #bd874a;
}

/*newsletter popup css end*/
/* 11. error page css heer  */
.header_error {
  border-bottom: 1px solid #ddd;
}

.error_form {
  text-align: center;
}

.error_form h1 {
  font-size: 200px;
  font-weight: 700;
  color: #bd874a;
  letter-spacing: 10px;
  line-height: 160px;
  margin: 0 0 27px;
}

@media only screen and (max-width: 767px) {
  .error_form h1 {
    font-size: 130px;
    line-height: 120px;
    letter-spacing: 4px;
    margin: 0 0 18px;
  }
}

.error_form h2 {
  color: #242424;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .error_form h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 23px;
  }
}

.error_form p {
  font-size: 17px;
  color: #747474;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .error_form p {
    font-size: 15px;
    margin-bottom: 28px;
    line-height: 23px;
  }
}

.error_form form {
  width: 450px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .error_form form {
    width: 100%;
  }
}

.error_form form input {
  padding: 0 93px 0 30px;
  background: #f8f8f8;
  border: 1px solid #ddd;
  color: #747474;
  height: 40px;
  width: 100%;
}

.error_form form button {
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  background: no-repeat;
  font-size: 20px;
  color: #747474;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  top: 0;
  text-transform: uppercase;
  padding: 0 15px;
  font-weight: 600;
}

.error_form form button:hover {
  color: #bd874a;
}

.error_form a {
  color: #fff;
  display: inline-block;
  background: #bd874a;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 42px;
  padding: 0 30px;
  text-transform: uppercase;
  margin-top: 35px;
}

.error_form a:hover {
  background: #242424;
}

.error_section {
  padding-bottom: 80px;
  padding-top: 75px;
}

@media only screen and (max-width: 767px) {
  .error_section {
    padding-bottom: 60px;
    padding-top: 50px;
  }
}

/*404 page css end*/
/* 26. compare css here*/
.compaer-page {
  margin: 32px 0 60px;
}

.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 305px;
  vertical-align: bottom;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  color: #454545;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #bd874a;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-stock {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background: #bd874a;
  border-radius: 4px;
  display: inline-block;
  width: 170px;
  padding: 10px 6px;
  transition: all 0.7s;
  color: #ffffff;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  background: #747474;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  transition: .3s;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #bd874a;
}

.compare-table .table tbody tr td.pro-ratting a {
  font-size: 14px;
  color: #747474;
}

.compare-table .table tbody tr td.pro-ratting a:hover {
  color: #bd874a;
}

/* 25. privacy-policy css here*/
.privacy_policy_main_area {
  padding: 16px 0 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .privacy_policy_main_area {
    padding: 0 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .privacy_policy_main_area {
    padding: 0 0 35px;
  }
}

.privacy_content {
  margin-bottom: 22px;
}

.privacy_content:last-child {
  margin-bottom: 0;
}

.privacy_content h2 {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 7px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .privacy_content h2 {
    font-size: 17px;
  }
}

.privacy_content h3 {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 7px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .privacy_content h3 {
    font-size: 17px;
  }
}

.privacy_content p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 19px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .privacy_content p {
    font-size: 14px;
  }
}

.privacy_content p a {
  color: #a43d21;
}

.privacy_content p a:hover {
  color: #bd874a;
}

.privacy_content.section_1 p {
  margin-bottom: 0;
}

.privacy_content.section_2 h2 {
  margin-bottom: 24px;
}

/*privacy policy css end*/
/* 27. coming soon css here*/
.coming_soon_area {
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  /* background-image: url(../img/bg/coming-soon.jpg); */
  background-image: url(../src/./assets/img/bg/coming-soon.jpg);
  position: relative;
  display: flex;
  align-items: center;
}

.coming_soon_area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.15;
}

.coming_soon_title {
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_title {
    margin-bottom: 33px;
  }
}

.coming_soon_title h2 {
  font-size: 37px;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_title h2 {
    font-size: 23px;
    line-height: 24px;
    margin-bottom: 11px;
  }
}

.coming_soon_title p {
  color: #ffffff;
  font-size: 15px;
  max-width: 730px;
  margin: 0 auto;
  line-height: 24px;
}

.coming_soon_timing {
  text-align: center;
  margin: 57px 0 42px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming_soon_timing {
    margin: 50px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .coming_soon_timing {
    margin: 33px 0 19px;
  }
}

.single_countdown {
  display: inline-block;
  margin-right: 35px;
}

@media only screen and (max-width: 479px) {
  .single_countdown {
    margin-right: 20px;
  }
}

.single_countdown:last-child {
  margin-right: 0;
}

.coming_soon_newsletter {
  text-align: center;
}

.coming_soon_newsletter .subscribe_form {
  margin-top: 0;
}

.coming_soon_newsletter .subscribe_form form {
  margin: 0 auto;
  width: 600px;
  position: relative;
  background: inherit;
  border: 1px solid #ddd;
  border-radius: 50px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form {
    width: 100%;
  }
}

.coming_soon_newsletter .subscribe_form form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input {
  color: #ffffff;
  font-size: 14px;
  padding: 0 120px 0 28px;
  background: inherit;
  height: 48px;
  width: 100%;
  border: 0;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form input {
    padding: 0 104px 0 28px;
    height: 44px;
  }
}

.coming_soon_newsletter .subscribe_form form button {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  background: inherit;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  height: 100%;
  padding: 0 18px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form button {
    padding: 0 12px;
  }
}

.coming_soon_newsletter .subscribe_form form button:hover {
  color: #bd874a;
}

.coming_soon_newsletter .subscribe_form form button::before {
  content: "";
  background: #ffffff;
  width: 2px;
  position: absolute;
  bottom: 8px;
  top: 8px;
  left: 0;
}

.coming_soon_newsletter h3 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: capitalize;
}

.coming_soon_newsletter .mailchimp-error {
  text-align: center;
  color: #ffffff;
}

.coming_soon_newsletter .mailchimp-success {
  text-align: center;
  color: #ffffff;
}

.coming_soon_logo {
  margin-bottom: 52px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_logo {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 479px) {
  .coming_soon_logo {
    margin-bottom: 30px;
  }
}

.coming_soon_link {
  text-align: center;
}

.coming_soon_link ul li {
  display: inline-block;
  margin-right: 20px;
}

.coming_soon_link ul li:last-child {
  margin-right: 0;
}

.coming_soon_link ul li a {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0 50px;
  display: inline-block;
  background: #bd874a;
  color: #747474;
  border-radius: 5px;
}

.coming_soon_link ul li a:hover {
  background: #747474;
  color: #ffffff;
}

.countdown_number {
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 8px;
  width: 60px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

@media only screen and (max-width: 479px) {
  .countdown_number {
    width: 55px;
    font-size: 20px;
  }
}

.countdown_title {
  font-size: 13px;
  text-transform: capitalize;
  color: #ffffff;
}

/*# sourceMappingURL=style.css.map */

.trending-products-swiper .swiper-button-next,
.trending-products-swiper .swiper-button-prev {
  color: #bd874a; /* Change arrow color */
}
.trending-products-swiper .swiper-button-next:hover,
.trending-products-swiper .swiper-button-prev:hover {
  color: #212121; /* Change color on hover */
}


.trending-products-swiper .swiper-button-next {
  right: 5px; /* Add gap from the content */
}

.trending-products-swiper .swiper-button-prev {
  left: 5px; /* Add gap from the content */
}

.shop-button-prev {
  position: absolute;
  top: 45%;
  left: 0;
  color: #3c4242;
}
.shop-button-next {
  position: absolute;
  top: 45%;
  right: 0;
  color: #3c4242;
}
.shop-thumb-button-next,
.shop-thumb-button-prev {
  position: absolute;
  top: 37%;
  color: #bd874a;
}
.shop-thumb-button-next {
  right: -12px;
}
.shop-thumb-button-prev {
  left: -12px;
}
.shop-button-prev,
.shop-button-next {
  position: absolute;
  top: 37%;
  color: #bd874a;
  z-index: 2;
}
.shop-button-next {
  right: 14px;
}
.shop-button-prev {
  left: 14px;
}

.mainPreview .swiper-pagination {
  display: flex;
  flex-direction: column;
  gap: 5px;
  top: 45% !important;
  left: 10px !important;
}
.mainPreview .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  top: 50%;
  left: 10px;
  background: #b0b0b0;
}

.mainPreview .swiper-pagination .swiper-pagination-bullet-active {
  background: #bd874a;
}
.product-preview-thumb .swiper-slide {
  height: 120px;
}
.product-preview-thumb .swiper-slide-thumb-active {
  border: 2px solid #bd874a;
  border-radius: 0.5rem;
  overflow: hidden;
}
